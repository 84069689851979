




















import Vue from "vue";
import Pageheader from "./components/Pageheader.vue";
import Announcement from "./components/Announcement.vue";
import Notifications from "./components/Notifications.vue";
import { EventBus } from "./lib/EventBus";
import { Autorisatie } from "./Autorisatie";

export default Vue.extend({
  components: {
    Pageheader,
    Notifications,
    Announcement,
  },

  computed: {
    autobedrijfNaam(): string {
      return this.$store.getters["Identity/AutobedrijfNaam"];
    },
    isTest(): boolean {
      return this.$store.getters["Environment/IsTest"];
    },
    showAnnouncement(): boolean {
      return this.$store.getters["Announcements/showRelease"];
    },

    fullSize(): boolean
    {
      return !!this.$route.meta?.fullSizeApp && this.$route.meta.fullSizeApp;
    }
  },

  mounted() {
    this.$i18n.locale = this.$store.getters["Identity/Language"];

    if (!this.hasAutorisatie(Autorisatie.GebruikerInloggen)) {
      EventBus.$emit("add-toastr", {
        text: "Geen inlog autorisatie voor MAX",
        type: "error",
      });
    }
  },

  methods: {
    hasAutorisatie(autorisatie: number): boolean {
      return this.$store.getters["Identity/HasAutorisatie"](autorisatie);
    },

    hasValidToken(): boolean {
      return this.$store.getters["Identity/userTokenIsValid"]();
    },
  },
});
