import mitt from "mitt";
//global instance:
const emitter = mitt();

export const EventBus = {
  $emit: function(key: string, data: any = {}): void {
    emitter.emit(key, data);
  },
  $on: function(key: string, data: any): void {
    emitter.on(key, data);
  },
  $off: function(key: string, data: any): void {
    emitter.off(key, data);
  },
};
