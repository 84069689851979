export default {
  yes: "FR: Ja",
  no: "FR: Nee",
  switchLanguage: "FR: Wijzig taal",
  menuoverzicht: "FR: Mijn overzicht",
  beheer: "FR: Beheer",
  searchPlaceholder: "FR: Zoeken...",
  new: "FR: Nieuw",
  newWorkflow: "FR: Nieuwe doorlopende workflow",
  newCampaign: "FR: Nieuwe campagne workflow",
  details: "FR: Details",
  select: "FR: Selecteer",
  apply: "FR: Toepassen",
  sortMostRecent: "FR: Datum aangepast",
  sortTitle: "FR: Titel",
  sortStartDate: "FR: Startdatum",
  sortName: "FR: Naam",
  save: "FR: Opslaan",
  cancel: "FR: Annuleren",
  active: "FR: Actief",
  today: "FR: Vandaag",
  now: "FR: Nu",
  minutes: "FR: minuten",
  hours: "FR: uren",
  days: "FR: dagen",
  weeks: "FR: weken",
  months: "FR: maanden",
  minute: "FR: minuut",
  hour: "FR: uur",
  day: "FR: dag",
  week: "FR: week",
  month: "FR: maand",
  close: "FR: Sluiten",
  undo: "FR: ongedaan maken",
  insert: "FR: Invoegen",
  collapse: "FR: Inklappen",
  expand: "FR: Uitklappen",
  send: "FR: Verzenden",
  logout: "FR: Uitloggen",
  application: "FR: Applicatie",
  settings: "FR: Instellingen",
  customerService: "FR: Klantenservice",
  rapportage: "FR: Rapportage",
  dashboard: "FR: Dashboard",
  doelgroepen: "FR: Doelgroepen",
  lef: "FR: LEF",
  actieveKlant: "FR: Actieve klant",
  werkplaatsplanner: "FR: Werkplaatsplanner",
  webtracking: "FR: Webtracking",
  voertuigbronnen: "FR: Voertuigbronnen",
  showInactive: "FR: Toon inactief",
  workflows: {
    title: "FR: Workflows",
    titleCampagne: "FR: Workflows",
    filteredCount: "FR: {tabtitle} ({count} van de {total})",
    searchPlaceholder: "FR: Zoek workflow...",
    when: "FR: Wanneer",
    starttrigger: "FR: Bij wijzigen datum",
    category: "FR: Categorie",
    labels: "FR: Labels",
    copy: "FR: Kopiëren",
    categoryChoice: {
      all: "FR: Alle workflows",
      lead: "FR: Lead workflows",
      sales: "FR: Sales workflows",
      aftersales: "FR: Aftersales workflows",
      delivery: "FR: Aflevering workflows"
    },
    campagnePeriod: {
      custom: "FR: Vrije periode",
      fromNow: "FR: Toekomst",
      lastMonths: "FR: Binnen 3 maanden",
      year: "FR: Dit jaar",
      lastYear: "FR: Vorig jaar"
    }
  },

  workflow: {
    onbekend: "FR: Onbekend",
    berichtaccorderen: "FR: Berichten vooraf goedkeuren",
    title: "FR: Workflow",
    tabWorkflows: "FR: Doorlopende workflows",
    tabCampagnes: "FR: Campagne workflows",
    copied: "FR: Kopie van {title}",
    subject: "FR: Onderwerp",
    paneltitle: "FR: Workflow details",
    insertTitle: "FR: Voer een titel in...",
    insertSubject: "FR: Voer een omschrijving in...",
    selectLabel: "FR: Selecteer label(s)",
    panelDetailTitle: "FR: Algemene informatie",
    starttitel: "FR: Startmoment",
    startmoment: "FR: Start",
    startprerequisites: "FR: Startvoorwaarden",
    addstartprerequisite: "FR: Toevoegen",
    selectstart: "FR: Kies een startmoment",
    selectwacht: "FR: Voer een periode in",
    selectkwalificatie: "FR: Kies een kwalificatie",
    contains: "FR: Bevat",
    containsnot: "FR: Bevat niet",
    selectleadtype: "FR: Kies een lead type",
    selectleadinitiatief: "FR: Kies een lead initiatief",
    selectsoortlead: "FR: Kies lead soort(en)",
    selectsoortleadsingle: "FR: Kies een soort lead",
    selectleadsource: "FR: Kies een leadbron",
    selectleadcartype: "FR: Kies soort gewenste auto",
    selectleadcarmake: "FR: Kies gewenste merk(en)",
    selectleadcartypespecified: "FR: Kies soort voertuig",
    selectaftersalescarmake: "FR: Kies merk(en)",
    selectaftersalescartype: "FR: Kies voertuigcategorie",
    selectleadbranch: "FR: Kies vestiging(en)",
    selectsalesbranch: "FR: Kies vestiging(en)",
    selectorderbranch: "FR: Kies vestiging(en)",
    selectGarantie: "FR: Selecteer garantiesoort",
    selectFactuursoort: "FR: Selecteer factuursoort",
    garantieSoort: "FR: Garantiesoort",
    selectendtitle: "FR: Einde",
    selectsteptitle: "FR: Stappen",
    selectActiegroep: "FR: Kies actiegroep(en)",
    stepbericht: "FR: Bericht",
    stepLEF: "FR: LEF",
    StepLefTitle: "FR: LEF",
    stepLEFKwalificatie: "FR: Kwalificatie",
    stepLEFDescription: "FR: Omschrijving",
    stepLEFInsertTitle: "FR: Voer een omschrijving in...",
    stepLEFleadType: "FR: Leadtype",
    stepLEFLeadInitiatief: "FR: Initiatief",
    stepVoorwaarde: "FR: Voorwaarde",
    stepVoorwaardeTitle: "FR: Voorwaarde",
    stepVoorwaardeDescription: "FR: Omschrijving",
    selectVoorwaarde: "FR: Kies een voorwaarde",
    selectVoorwaardeAfsluitredenSucces: "FR: Kies afsluitreden(en)",
    selectVoorwaardeAfsluitreden: "FR: Kies afsluitreden(en)",
    selectVoorwaardeAfsluitredenSales: "FR: Kies afsluitreden(en)",
    selectVoorwaardeLopendeWorkflows: "FR: Kies gestarte workflow(s)",
    selectVoorwaardeOpenLeadType: "FR: Kies een leadtype",
    selectVoorwaardeHeeftVoertuig: "FR: Kies een vergelijking",
    selectVoorwaardeBrandSame: "FR: Voor hetzelfde merk",
    selectVoorwaardeBrandIndependent: "FR: Hetzelfde of andere merken",
    merk: "FR: Merk",
    profiel: "FR: Profiel",
    leadtype: "FR: Leadtype",
    leadinitiatief: "FR: Initiatief",
    hasOpenSalesLead: "FR: Heeft openstaande saleslead",
    hasOpenAftersalesLead: "FR: Heeft openstaande aftersaleslead",
    hasNoOpenLead: "FR: Heeft geen openstaande lead",
    hasNoOpenSalesLead: "FR: Heeft geen openstaande saleslead",
    hasNoOpenAftersalesLead: "FR: Heeft geen openstaande aftersaleslead",
    hasNoOpenSalesOrAflevering: "FR: Heeft geen lopend salestraject/aflevering",
    hasNoOpenSales: "FR: Heeft geen lopend salestraject",
    hasNoOpenAflevering: "FR: Heeft geen lopende aflevering",
    hasTelefoon: "FR: Heeft telefoonnummer",
    hasNoTelefoon: "FR: Heeft geen telefoonnummer",
    hasEmail: "FR: Heeft e-mailadres",
    hasNoEmail: "FR: Heeft geen e-mailadres",
    kanBenaderen: "FR: Heeft e-mailadres/telefoonnummer",
    kanNietBenaderen: "FR: Heeft geen e-mailadres/telefoonnummer",
    abort: "FR: Workflow afbreken",
    noAbort: "FR: Workflow laten doorlopen",
    tileAbortDescription: "FR: Kan workflow afbreken",
    expiratie: "FR: Wachttermijn tot 'nee'",
    preconditionDuringWorkflow: "FR: Als voorwaarde niet meer voldoet tijdens workflow",
    expiratieNegate: "FR: Wachttermijn tot 'ja'",
    selectlinkcklick: "FR: Kies link(s)",
    stepEinde: "FR: Einde",
    stepEindeResult: "FR: Resultaat",
    stepEindeResultOk: "FR: Succesvol",
    stepEindeResultNok: "FR: Niet succesvol",
    stepEindeResultNA: "FR: Niet van toepassing",
    stepTimer: "FR: Voor",
    stepTimerTitle: "FR: Wacht",
    selectBericht: "FR: Kies een bericht",
    changeBericht: "FR: Kies een ander bericht",
    noBericht: "FR: Geen bericht geselecteerd",
    selectResultaat: "FR: Kies een resultaat",
    stepsRemoved: "FR: verwijderd",
    saved: "FR: Workflow is opgeslagen",
    selectKlantCategorie: "FR: Kies klantcategorie",
    orderVoertuigCategorie: "FR: Kies voertuigcategorie",
    orderVoertuigBrandstof: "FR: Kies voertuigbrandstof",
    aftersalesVoertuigBrandstof: "FR: Kies voertuigbrandstof",
    keepWhichPath: "FR: Workflow continueren met ",
    optin: "FR: Opt\u2011in: ",
    checkOptin: "FR: Opt\u2011in controleren",
    noOptin: "FR: Geen opt\u2011in controle",
    recenteLeadDelta: "FR: In de afgelopen",
    numberOfVisits: "FR: Minimum aantal paginaweergaven sinds startmoment",
    hasRecentSalesLead: "FR: Heeft recent aangemaakte saleslead",
    hasRecentAftersalesLead: "FR: Heeft recent aangemaakte aftersaleslead",
    hasNoRecentLead: "FR: Heeft geen recent aangemaakte lead",
    hasNoRecentSalesLead: "FR: Heeft geen recente saleslead",
    hasNoRecentAftersalesLead: "FR: Heeft geen recente aftersaleslead",
    hasNoRecentSales: "FR: Heeft geen recent aangemaakt salestraject",
    leadInitiatiefType: {
      onbekend: "FR: Onbekend",
      klant: "FR: Klant",
      autobedrijf: "FR: Autobedrijf",
      importeur: "FR: Importeur",
    },
    selectInitiatiefType: "FR: Selecteer initiatief",
    subconditions: "FR: Subvoorwaarden",
    meetsSubconditions: "FR: Voldoet aan",
    allSubconditions: "FR: Alle subvoorwaarden",
    anySubconditions: "FR: Eén van de subvoorwaarden",
    addSubcondition: "FR: Toevoegen",
    subconditionCriteria: "FR: Kies criteria",
    tileSubconditionsAllDescription:
      "FR: Voldoet aan alle<br>{count} subvoorwaarden",
    tileSubconditionsAnyDescription:
      "FR: Voldoet aan één van<br>{count} subvoorwaarden",
    tileSubconditionsCountDescription:
      "FR: Bevat {count} subvoorwaarde | Bevat {count} subvoorwaarden",
    selectRelatienaam: "FR: Voer een naam in",
    category: {
      all: "FR: Alle workflows",
      lead: "FR: Lead",
      sales: "FR: Sales",
      aftersales: "FR: Aftersales",
      delivery: "FR: Aflevering",
      campaign: "FR: Campagne"
    },
    dataEntiteit: {
      relatie: "FR: Relatie",
      voertuig: "FR: Voertuig"
    },
    startstepTriggerRestart: "FR: Workflow opnieuw starten",
    startstepTriggerOnceOnly: "FR: Workflow niet opnieuw starten",
    startstepTriggerDataEntry: "FR: op het moment van invullen",
    startstepTriggerDataEntryCapital: "FR: Op het moment van invullen",
    startDescriptionCount: "FR: {filtericon} 1 startvoorwaarde |{filtericon} {count} startvoorwaarden",		
    startDescriptionTriggerBefore: "FR: voor",
    startDescriptionTriggerAfter: "FR: na",
    startDescriptionTrigger: "FR: {startdescription} {beforeafter} de ingevulde datum",	
    startstepTriggerOnDate: "FR: op de ingevulde datum", 
    startstepTriggerDate: "FR: De ingevulde datum", 
    startstepCampagneTriggerDate: "FR: Workflow starten per", 
    startstepCampagneDate: "FR: Datum", 
    startstepCampagneTime: "FR: Tijd", 
    startstepCampagneMax: "FR: Maximum aantal per dag", 
    webtrackingDisplay: "FR: minimaal {amount} weergaven sinds start",
    factuurDisplay: "FR: meer dan {amount} {units}",
    tenaamstellingDisplayMore: "FR: langer dan {timespan} geleden",
    tenaamstellingDisplayLess: "FR: korter dan {timespan} geleden",
    nextMaintenanceDisplayBefore: "FR: binnen {timespan} voor Volgend onderhoud",
    nextMaintenanceDisplayAfter: "FR: binnen {timespan} na Volgend onderhoud",
    nextAPKDisplayBefore: "FR: binnen {timespan} voor APK",
    nextAPKDisplayAfter: "FR: binnen {timespan} na APK",
    lastSoldVehicleDisplayBefore: "FR: binnen {timespan} voor Laatste tenaamstelling",
    lastSoldVehicleDisplayAfter: "FR: binnen {timespan} na Laatste tenaamstelling",
    voertuigDisplayMore: "FR: meer dan {amount}",
    voertuigDisplayLess: "FR: minder dan {amount}",
    voertuigDisplayEqual: "FR: gelijk dan {amount}",
    addDoelgroep: "FR: Toevoegen",
    multipleDoelgroepInformation: "FR: Wanneer een relatie zich in meerdere doelgroepen bevindt, wordt voor het vullen van samenvoegvelden in berichten de bovenste doelgroep gebruikt, in dit geval: {doelgroepNaam}",
    errorMessages: {
      readonly: "FR: Deze workflow kan niet meer worden aangepast omdat de campagne al is gestart.",
      workflowTitle: "FR: Naam workflow ontbreekt.",
      startStepNoTrigger: "FR: Startmoment in start is verplicht.",
      startStepNoGarantie: "FR: Een garantiesoort is verplicht.",
      startStepNoStartMoment: "FR: Een startmoment is verplicht.",
      startStepNoStartTijd: "FR: Een starttijd is verplicht.",
      startStepNoAantalPerDag:
        "FR: Een maximaal aantal workflows per dag is verplicht.",
      startStepNoDoelgroepen:
        "FR: Er moet ten minste één doelgroep geselecteerd worden.",
      startStepNoEntiteit:
        "FR: Er moet een keuze worden gemaakt om te starten per relatie of voertuig.",
      startStepInactiveDoelgroep: "FR: Één of meerdere doelgroepen zijn inactief.",
      startStepNoVoertuigEntiteit: "FR: Er moet in alle doelgroepen een voertuig zitten.",
      eindStep: "FR: Een of meerdere eindstappen ontbreken.",
      eindStepNoResult: "FR: Resultaten in eindstappen zijn verplicht.",
      wachtStep: "FR: Periode in wachtstappen zijn verplicht.",
      berichtStep: "FR: Bij een berichtenstap moet een bericht geselecteerd zijn.",
      berichtStepMissingEntities:
        "FR: Het bericht bevat {entiteiten} die niet gevuld worden in deze workflow.",
      voorwaardeStepMissingEntities:
      "FR: De workflow bevat {entiteiten} die niet ge-evalueerd kunnen worden.",
      lefStep: {
        omschrijving: "FR: Omschrijvingen in LEF stappen zijn verplicht.",
        kwalificaties: "FR: Kwalificaties in LEF stappen zijn verplicht.",
        leadtype:
          "FR: Lead type in LEF stappen is verplicht bij sales en afleveringen.",
      },
      voorwaardeCombinatieStep: {
        criteria:
          "FR: Criteria in voorwaardestappen met meerdere subvoorwaarden is verplicht.",
      },
      voorwaardeStep: {
        criteria: "FR: Criteria in voorwaardestappen zijn verplicht.",
        gemist: "FR: Gemiste afsluitredenen in voorwaardestappen zijn verplicht.",
        soortLead: "FR: Soort lead in voorwaardestappen zijn verplicht.",
        gewensteAutosoorten:
          "FR: Gewenste autosoorten in voorwaardestappen zijn verplicht.",
        carmake: "FR: Merken in voorwaardestappen zijn verplicht.",
        vestigingen: "FR: Vestigingen in voorwaardestappen zijn verplicht.",
        afsluitRedenen:
          "FR: Afsluitsuccesvol redenen in voorwaardestappen zijn verplicht.",
        link: "FR: Links in voorwaardestappen zijn verplicht.",
        leadvoertuigtype:
        "FR: Het soort voertuig in lead voorwaardestappen is verplicht.",
        gemistSales:
          "FR: Gemiste afsluitredenen in voorwaardestappen zijn verplicht.",
        salesAutosoorten:
          "FR: Sales autosoorten in voorwaardestappen zijn verplicht.",
        aftersalesVoertuigCategorieen:
          "FR: Voertuig categorie in voorwaardestappen zijn verplicht.",
        salesAutomake: "FR: Merken in voorwaardestappen zijn verplicht.",
        orderAutomake: "FR: Merken in voorwaardestappen zijn verplicht.",
        vestigingenExternSales:
          "FR: Vestigingen in voorwaardestappen zijn verplicht.",
        orderAutosoorten:
          "FR: Order autosoorten in voorwaardestappen zijn verplicht.",
        vestigingenExternOrder:
          "FR: Vestigingen in voorwaardestappen zijn verplicht.",
        actiegroepVerkocht:
          "FR: Actiegroep verkocht in voorwaardestappen zijn verplicht.",
        actiegroepGoedgekeurd:
          "FR: Actiegroep goedgekeurd in voorwaardestappen zijn verplicht.",
        actiegroepBesteld:
          "FR: Actiegroep besteld in voorwaardestappen zijn verplicht.",
        actiegroepAutoBinnen:
          "FR: Actiegroep auto binnen in voorwaardestappen zijn verplicht.",
        actiegroepAfspraakLevering:
          "FR: Actiegroep afspraak levering in voorwaardestappen zijn verplicht.",
        actiegroepAfgeleverd:
          "FR: Actiegroep afgeleverd in voorwaardestappen zijn verplicht.",
        klantCategorie: "FR: Klantcategorie in voorwaardestappen is verplicht.",
        kwalificatie: "FR: Kwalificatie in voorwaardestappen is verplicht.",
        leadbron: "FR: Leadbron in voorwaardestappen is verplicht.",
        lopendeWorkflows:
          "FR: Lopende workflows in voorwaardestappen zijn verplicht.",
        timespan: "FR: Periode in voorwaardestappen is verplicht.",
        referenceid: "FR: Referentiedatum in voorwaardestappen zijn verplicht.",
        garantie: "FR: Garanties in voorwaardestappen zijn verplicht.",
        factuursoorten: "FR: Factuursoorten in voorwaardestappen zijn verplicht.",
        heeftOpenLead: "FR: Leadtype in voorwaardestappen is verplicht.",
        heeftInitiatief: "FR: Initiatief in voorwaardestappen is verplicht.",
        optinProfiel:
          "FR: Profiel bij opt-in controle in voorwaardestappen is verplicht",
        optinMedium:
          "FR: Medium bij opt-in controle in voorwaardestappen is verplicht",
        expiratieMaxSubvoorwaarden:
          "FR: Wachttermijn kan niet worden gebruikt bij meerdere subvoorwaarden",
        bedragnegatief: "FR: Geen geldig bedrag ingevuld.",
        aantalnegatief: "FR: Geen geldig aantal ingevuld.",
        invalidCompare: "FR: Geen geldig vergelijker ingevuld.",
        relatieNamen:
          "FR: Een of meerdere namen in voorwaardestappen zijn verplicht.",
        orderVoertuigCategorie:
          "FR: Een of meerdere categorieen in voorwaardestappen zijn verplicht.",
        orderVoertuigBrandstof:
          "FR: Een of meerdere brandstoffen in voorwaardestappen zijn verplicht.",
        leadVoertuigBrandstof:
          "FR: Een of meerdere brandstoffen in voorwaardestappen zijn verplicht.",
        aftersalesVoertuigBrandstof:
          "FR: Een of meerdere brandstoffen in voorwaardestappen zijn verplicht.",
        invalidwebtrackers:
          "FR: Een of meerdere webtrackers in voorwaardestappen zijn verplicht.",
      },
      campagneAantalLimitExceeded: "FR: Het maximum aantal dat per dag verstuurd mag worden is gelimiteerd op {count}. Indien dit opgehoogd dient te worden, verzoeken wij u contact op te nemen met onze Customer Service.",
      campagneStartToday: "FR: Let op: Deze campagne zal over {count} minuten starten.",
      berichtStepMissingVestiging: 
        "FR: Omdat er geen aflevering, salestraject, lead of voertuig gegarandeerd kan worden op dit punt in de workflow, zal de standaardvestiging gebruikt worden voor het invullen van de vestiging samenvoegvelden in dit bericht."
    },
  },

  sjablonen: {
    title: "FR: Berichten",
    searchPlaceholder: "FR: Zoek bericht...",
    blocks: "FR: Blokken",
    general: "FR: Algemeen",
    inworkflows: "FR: Workflows",
    communication: "FR: Communicatie",
    category: "FR: Categorie",
    workflow: "FR: Workflow",
    make: "FR: Merk",
    labels: "FR: Labels",
    email: "FR: E-mail",
    sms: "FR: SMS",
    copy: "FR: Kopiëren",
    createTemplate: "FR: Maak template",
    createdTemplate: "FR: Template aangemaakt",
  },

  sjabloon: {
    copied: "FR: Kopie van {title}",
    panelTitle: "FR: Berichtdetails",
    panelDetailTitle: "FR: Algemene informatie",
    title: "FR: Bericht",
    subject: "FR: Onderwerp",
    category: "FR: Categorie",
    label: "FR: Label",
    insertTitle: "FR: Voer een titel in...",
    insertSubject: "FR: Voer een onderwerp in...",
    chooseCategory: "FR: Kies een categorie",
    allCategories: "FR: Alle categorieën",
    actief: "FR: Actief",
    inactief: "FR: Inactief",
    allLabels: "FR: All labels",
    selectLabel: "FR: Selecteer label(s)",
    email: "FR: E-mail",
    sms: "FR: SMS",
    opgeslagen: "FR: Opgeslagen",
    mergetags: "FR: Samenvoegvelden",
    selectmergetag: "FR: Samenvoegveld invoegen",
    undoSMS: "FR: SMS bericht is verwijderd",
    undoEmail: "FR: E-Mail bericht is verwijderd",
    chooseBericht: "FR: Kies een bericht",
    modelSpecificLayout: "FR: Model specifieke opmaak",
    merk: "FR: Merk",
    model: "FR: Model",
    allMakes: "FR: Alle merken",
    selectMerk: "FR: Merk toevoegen",
    selectModel: "FR: Model toevoegen",
    nonSelectedMerk: "FR: Alle merken",
    nonSelectedModel: "FR: Alle modellen",
    selectedMerk: "FR: Overige merken",
    selectedModel: "FR: Overige modellen",
    notAvailableMerk: "FR: Merk niet beschikbaar",
    notAvailableOpties: "FR: Geen opties beschikbaar",
    noResult: "FR: Geen resultaat gevonden",
    merkDeleted: "FR: Merk is verwijderd",
    modelDeleted: "FR: Model is verwijderd",
    preview: "FR: Voorbeeld",
    sendtest: "FR: Test bericht",
    saved: "FR: Bericht is opgeslagen",
    voorkeurinformatie:
      "FR: Door de tabbladen te verslepen kan de voorkeur tijdens het versturen worden bepaald",
    smsinformatie:
      "FR: Het aantal smsjes wordt vertoond. Een sms met normale leestekens bevat de standaard 160 karakter. Met speciale leestekens is het maximaal aantal karakters 70. De lengte van de sms is afhankelijk van de inhoud van de samenvoegvelden.",
    noWorkflow: "FR: Niet gekoppeld aan een workflow",
    testSMSDetailTitle: "FR: Test SMS",
    testSMSTitle: "FR: Test verzenden aan",
    insertPhoneNumber: "FR: Voer een mobielnummer in...",
    testMailDetailTitle: "FR: Test e-mail",
    testEmailTitle: "FR: Test verzenden aan",
    insertEmail: "FR: Voer een e-mailadres in...",
    testMailSend: "FR: Test e-mail is verzonden",
    testMailSendFailed: "FR: Test e-mail is niet verzonden",
    testSMSSend: "FR: Test SMS is verzonden",
    testSMSSendFailed: "FR: Test SMS is niet verzonden",
    webtrackingactive: "FR: Webtracking",
    consumersettingsactive: "FR: Opt\u2011in",
    consumersettingslabel: "FR: Profiel",
    consumersettings: "FR: Kies een profiel...",
    errormessages: {
      title: "FR: Het veld bericht is verplicht",
      omschrijving: "FR: Het veld onderwerp is verplicht",
      omschrijvingTooLong: "FR: Het veld onderwerp mag maximaal 255 karakters bevatten." ,
      testemailaddress: "FR: Een geldig e-mailadres is verplicht",
      testephonenumber: "FR: Een geldig mobielnummer is verplicht",
      isActiveInvalid: "FR: Bericht wordt nog gebruikt in een actieve workflow",
      consumersettingsInvalid: "FR: Kies een profiel bij Opt\u2011in",
      consumersettingsTag:
        "FR: Een of meerdere e-mails missen een opt\u2011in afmeldlink",
    },
  },

  sentMessages: {
    versturen: "FR: Versturen",
    menuTitle: "FR: Niet verzonden berichten",
    title:
      "FR: Niet verzonden bericht ({count})| Niet verzonden berichten ({count})",
    workflow: "FR: Workflow",
    email: "FR: E-mail",
    sms: "FR: SMS",
    recipient: "FR: Ontvanger",
    status: "FR: Foutstatus",
    errorMessage: "FR: Foutmelding",
    delete: "FR: Verwijderen",
    confirmDelete: "FR: Bevestigen",
    communication: "FR: Communicatie",
    berichtTitel: "FR: Bericht titel",
    badgeTitle: "FR: 1 niet verzonden bericht | {count} niet verzonden berichten",
  },
};
