export default {
  namespaced: true,

  mutations: {},

  actions: {},

  getters: {
    IsTest(): boolean {
      const host = window.location.host;
      return (
        host.indexOf(".vijzelmolen") !== -1 ||
        host.indexOf("localhost") !== -1
      );
    },
  },
};
