


















import Vue from "vue";
import { EventBus } from "./../lib/EventBus";
import { newId } from "../components/workfloweditor/WorkflowUtils";
enum STATE {
  IDLE = 0,
  DESTROYED = 2
}

export default Vue.extend({
  props: {
    group: {
      type: String,
      default: ""
    },
    width: {
      type: [Number, String],
      default: 300
    },
    max: {
      type: Number,
      default: Infinity
    },
    classes: {
      type: String,
      default: "vue-notification"
    }
  },
  data: () => {
    return {
      list: [] as any[],
      duration: 5000,
      speed: 300
    };
  },
  created() {
    EventBus.$on("add-toastr", this.addItem);
  },
  beforeDestroy() {
    EventBus.$off("add-toastr", this.addItem);
  },
  computed: {
    actualWidth(): number {
      return Number(this.width);
    },
    active(): any[] {
      return this.list.filter(v => v.state !== STATE.DESTROYED);
    },
    styles() {
      const width: number = this.actualWidth;
      const suffix = "px";

      return {
        width: width + suffix,
        top: "0px",
        left: `calc(50% - ${width / 2}${suffix})`
      };
    }
  },
  methods: {
    addItem(event: any) {
      event.group = event.group || "";

      if (this.group !== event.group) {
        return;
      }

      if (event.clean || event.clear) {
        this.destroyAll();
        return;
      }

      let { title, text, type, data } = event;

      let item = {
        id: newId(),
        title,
        text,
        type,
        state: STATE.IDLE,
        speed: this.speed,
        length: this.duration + 2 * this.speed,
        data
      } as any;

      if (this.duration >= 0) {
        item.timer = setTimeout(() => {
          this.destroy(item);
        }, item.length);
      }
 
      let indexToDestroy = -1;

      this.list.unshift(item);

      if (this.active.length > this.max) {
        indexToDestroy = this.active.length - 1;
      }

      if (indexToDestroy !== -1) {
        this.destroy(this.active[indexToDestroy]);
      }
    },
    notifyClass: function(item: any): any {
      return ["notification", this.classes, item.type];
    },

    destroy(item: any) {
      clearTimeout(item.timer);
      item.state = STATE.DESTROYED;
      this.clean();
    },

    destroyAll() {
      this.active.forEach(this.destroy);
    },
    clean: function() {
      this.list = this.list.filter(v => v.state !== STATE.DESTROYED);
    }
  }
});
