
































































































































































































































import Vue from "vue";
import { Tooltip } from "bootstrap";
import { Options } from "@popperjs/core/lib/types";
import axios from "axios";
import ButtonAction from "../components/ButtonAction.vue";
import IconMax from "../components/reversed/IconMAX.vue";
import IconUnsentMessages from "../components/reversed/IconUnsentMessages.vue";
import { Autorisatie } from "../Autorisatie";
import { Feature } from "../Feature";
import { EventBus } from "../lib/EventBus";
import { newId } from "../components/workfloweditor/WorkflowUtils";
export default Vue.extend({
  components: {
    ButtonAction,
    IconMax,
    IconUnsentMessages,
  },

  props: {
    title: String,
  },

  data() {
    return {
      id: "",
      Autorisatie: Autorisatie,
      Feature: Feature,
      tooltip: null as Tooltip | null,
    };
  },

  created() {
    this.id = newId();
  },

  mounted() {
    this.tooltip = new Tooltip(this.$refs.unsentMessageContainer as Element, {
      popperConfig: (defaultBsPopperConfig: Partial<Options>) => {
        //https://github.com/twbs/bootstrap/issues/36432
        if (!!defaultBsPopperConfig.modifiers)
          defaultBsPopperConfig.modifiers.push({
            name: "FixDynamicTitles",
            enabled: true,
            phase: "beforeRead",
            fn({ state }: any) {
              // Fix for dynamic titles using `data-bs-title`.
              const title = state.elements.reference.dataset.bsTitle;
              if (!!title) {
                state.elements.popper.querySelector(
                  ".tooltip-inner"
                ).innerHTML = title;
              }
            },
          });
        return defaultBsPopperConfig;
      },
    });
  },

  beforeDestroy() {
    if (!!this.tooltip) this.tooltip.hide();
  },

  computed: {
    avatarLabel(): string {
      if (this.$store.getters["Identity/IsAdmin"]) {
        return this.$store.getters["Identity/UserNaam"];
      }
      return `${this.$i18n.t("settings")}`;
    },

    customerServiceLabel(): string {
      return `${this.$i18n.t("customerService")}`;
    },

    hasUnreadUnsentMessages(): boolean {
      return this.unreadUnsentMessageCount > 0;
    },

    unreadUnsentMessageCount(): number {
      return this.$store.getters["Notifications/UnreadUnsentMessageCount"];
    },
  },

  methods: {
    unsentMessagesClick() {
      if (this.$router.currentRoute.name == "sentmessages") {
        EventBus.$emit("unsentMessagesClick");
      } else {
        this.$router.push({ name: "sentmessages" });
      }
    },
    hasAutorisatie(autorisatie: number) {
      return this.$store.getters["Identity/HasAutorisatie"](autorisatie);
    },
    isAdmin() {
      return this.$store.getters["Identity/IsAdmin"];
    },
    hasFeature(feature: Feature) {
      return this.$store.getters["Identity/HasFeature"](feature);
    },
    switchLang() {
      //todo locale in Vuex zetten en cookie voor F5
      if (this.$i18n.locale == "nl") this.$i18n.locale = "en";
      else this.$i18n.locale = "nl";
    },

    logout(this: any) {
      sessionStorage.clear();
      window.location.href = "/api/maxidentity/v1/logout";
    },
  },
});
