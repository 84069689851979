export default {
  observe(store: any) {
    let refreshData = {
      PollTimer: null as null | number
    };

    const refresh = () => {
      store.dispatch("Notifications/refreshNotifications").then(() => {
        refreshData.PollTimer = window.setTimeout(refresh, 10000);
      });
    };

    if (store.getters["Identity/hasUserToken"]) refresh();
    else
      store.watch(
        (state: any, getters: any) => getters["Identity/hasUserToken"],
        (newValue: boolean) => {
          if (newValue && !refreshData.PollTimer) {
            refresh();
          }
        }
      );
  }
};
