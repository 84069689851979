import Vue from "vue";
import random from "lodash/random";
import isInteger from "lodash/isInteger";
import WorkflowConverter from "./WorkflowConverter";
import {
  IWorkflow,
  IWorkflowNode,
  WorkflowNodeType,
  WachtEenheid,
  ILEFNodeProperties,
  IBerichtNodeProperties,
  IEindeNodeProperties,
  IStartNodeProperties,
  ICombinatieVoorwaardeNodeProperties,
  IVoorwaardeNodeProperties,
  IWorkflowNodeConditions,
} from "./IWorkflow";

import isEmpty from "lodash/isEmpty";
export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export enum TimeDirection {
  After = 1,
  Before = 2,
}

export const newId = function() {
  return "zxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xyz]/g, (char) => {
    const r = !!crypto
      ? (window.crypto.getRandomValues(new Uint32Array(1))[0] *
          Math.pow(2, -32) *
          16) |
        0
      : random(0, 15);
    const v = char === "x" ? r : char === "z" ? random(0, 15) : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const createNode = function(
  nodetype: WorkflowNodeType,
  withPlaceholder: boolean,
  parentId: string,
  id: string = ""
): IWorkflowNode {
  const nodeId = !!id ? id : newId();
  let node = {
    Id: nodeId,
    ParentStepId: parentId,
    StepTypeId: nodetype,
    Properties: {},
    Description: "",
    Children: [] as IWorkflowNode[],
    Conditions: [] as IWorkflowNodeConditions[],
  };

  switch (nodetype) {
    case WorkflowNodeType.Placeholder:
      node.Properties = {};
      break;
    case WorkflowNodeType.Start:
      node.Properties = {
        Id: null,
        TimeValue: null,
        TimeUnit: null,
        IsTimedStart: false,
        Garantie: null,
      } as IStartNodeProperties;
      break;
    case WorkflowNodeType.LEF:
      node.Properties = {
        Id: null,
        LeadType: null,
      } as ILEFNodeProperties;
      break;
    case WorkflowNodeType.Bericht:
      node.Properties = {
        BerichtId: null,
        BerichtTypeOrder: [],
      } as IBerichtNodeProperties;
      break;
    case WorkflowNodeType.Einde:
      node.Properties = {
        Id: null,
      } as IEindeNodeProperties;
      break;
    case WorkflowNodeType.Voorwaarde:
      node.Properties = {
        Id: null,
        Description: null,
        Operator: null,
      } as ICombinatieVoorwaardeNodeProperties;

      node.Conditions = [
        {
          Id: newId(),
          Properties: {
            Id: null,
            ReferenceId: null,
            BerichtId: null,
            Description: null,
            Amount: null,
            NegateConditional: null,
            AfsluitredenenGemist: null,
            AfsluitredenenSuccesvol: null,
            SoortenLead: null,
            SoortenAutoGewenst: null,
            MerkenAutoGewenst: null,
            Vestigingen: null,
            BerichtLinks: null,
            AfsluitredenenGemistSales: null,
            SalesSoortenAuto: null,
            AftersalesVoertuigCategorieen: null,
            MerkenAutoSales: null,
            MerkenAutoAftersales: null,
            MerkenAutoOrder: null,
            OrderSoortenAuto: null,
            ActiegroepenVerkocht: null,
            ActiegroepenGoedgekeurd: null,
            ActiegroepenBesteld: null,
            ActiegroepenAutoBinnen: null,
            ActiegroepenAfspraakLevering: null,
            ActiegroepenAfgeleverd: null,
            KlantCategorieen: null,
            Leadbronnen: null,
            Kwalificaties: null,
            LopendWorkflows: null,
            MerkMatch: false,
            InitiatiefMatch: null,
            OpenLeadType: null,
            Garanties: null,
            OrderVoertuigCategorieen: null,
            OrderVoertuigBrandstoffen: null,
            Media: null,
            RelatieNamen: null,
            LeadSoortenBrandstoffen: null,
            AftersalesSoortenBrandstoffen: null,
            LeadVoertuigType: null,
            WebsiteTrackers: null,
            Factuursoorten: null
          } as IVoorwaardeNodeProperties,
        } as IWorkflowNodeConditions,
      ];

      break;
  }

  if (withPlaceholder && nodetype !== WorkflowNodeType.Einde) {
    node.Children.push(
      createNode(WorkflowNodeType.Placeholder, false, nodeId, newId())
    );
    if (nodetype === WorkflowNodeType.Voorwaarde) {
      node.Children.push(
        createNode(WorkflowNodeType.Placeholder, false, nodeId, newId())
      );
    }
  }
  return node;
};

export const addNodeToWorkflow = function(
  nodetype: WorkflowNodeType,
  parentId: string,
  placeholderId: string,
  workflow: IWorkflow
): IWorkflowNode {
  let newNode = createNode(nodetype, true, parentId);
  const dict = WorkflowConverter.toDictionary(workflow);
  const parentNode = dict[newNode.ParentStepId!];
  const newChildren = parentNode.Children.map((child) => {
    return child.Id === placeholderId ? newNode : child;
  });

  Vue.set(parentNode, "Children", newChildren);
  return newNode;
};

export const insertNodeToWorkflow = function(
  nodetype: WorkflowNodeType,
  insertId: string,
  childNode: IWorkflowNode,
  workflow: IWorkflow,
  keepYes: boolean
): IWorkflowNode {
  let newNode = createNode(nodetype, false, childNode.ParentStepId!);
  const dict = WorkflowConverter.toDictionary(workflow);
  const parentNode = dict[newNode.ParentStepId!];
  if (nodetype === WorkflowNodeType.Voorwaarde && !keepYes) {
    newNode.Children = [
      createNode(WorkflowNodeType.Placeholder, false, parentNode.Id),
      childNode,
    ];
  } else if (nodetype === WorkflowNodeType.Voorwaarde && keepYes) {
    newNode.Children = [
      childNode,
      createNode(WorkflowNodeType.Placeholder, false, parentNode.Id),
    ];
  } else {
    newNode.Children = [childNode];
  }

  let newChildren = parentNode.Children.map((child) => {
    return child.Id === insertId ? newNode : child;
  });

  newChildren.forEach((element) => {
    const elementId = element.Id;
    element.Children.forEach((childchild) => {
      childchild.ParentStepId = elementId;
    });
  });

  Vue.set(parentNode, "Children", newChildren);

  return newNode;
};

export const deleteNodeFromWorkflow = function(
  workflow: IWorkflow,
  node: IWorkflowNode,
  keepYes: boolean
): void {
  const dict = WorkflowConverter.toDictionary(workflow);
  let parentNode = dict[node.ParentStepId!];
  let childNodes = node.Children;
  let childNode = null as null | IWorkflowNode;
  if (childNodes.length > 1) {
    childNode = keepYes ? childNodes[0] : childNodes[1];
  } else if (childNodes.length === 1) {
    childNode = childNodes[0];
  }

  if (!!childNode) {
    childNode.ParentStepId = parentNode.Id;
  } else if (node.StepTypeId === WorkflowNodeType.Einde) {
    childNode = createNode(WorkflowNodeType.Placeholder, false, parentNode.Id);
  }

  if (!!childNode) {
    let newChildren = parentNode.Children.map((child) => {
      return child.Id === node.Id ? childNode : child;
    });

    Vue.set(parentNode, "Children", newChildren);
  }
};

export const getTimespanOptions = function(
  getLang: (key: string) => string
): {
  id: WachtEenheid;
  name: string;
  selected: boolean;
}[] {
  return [
    {
      id: WachtEenheid.Minute,
      name: getLang("minutes"),
      selected: false,
    },
    {
      id: WachtEenheid.Hour,
      name: getLang("hours"),
      selected: false,
    },
    {
      id: WachtEenheid.Day,
      name: getLang("days"),
      selected: false,
    },
    {
      id: WachtEenheid.Week,
      name: getLang("weeks"),
      selected: false,
    },
    {
      id: WachtEenheid.Month,
      name: getLang("months"),
      selected: false,
    },
  ];
};

export const convertTimeInput = function(
  getLang: (key: string) => string,
  newInputValue: string | null,
  newWachtUnit: WachtEenheid
): {
  Description: string;
  Properties: {
    TimeUnit: number;
    TimeValue: null | number; //altijd posief waarde
  };
} {
  let newDescription = "";

  let timeval = null as null | number;
  if (
    newInputValue === null ||
    newInputValue === undefined ||
    newInputValue === ""
  ) {
    timeval = null;
  } else if (isInteger(newInputValue)) {
    timeval = Math.abs(Number(newInputValue));
  } else {
    let parsed = parseInt(newInputValue as string, 10);
    timeval = isInteger(parsed) ? Math.abs(parsed) : null;
  }

  let newProperties = {
    TimeUnit: newWachtUnit,
    TimeValue: timeval,
  };

  const newInput = !!timeval ? timeval : 0;
  const getDescription = (input: number, singular: string, plural: string) => {
    return input + " " + (input === 1 ? singular : plural);
  };

  switch (newWachtUnit) {
    case WachtEenheid.Hour:
      newDescription = getDescription(
        newInput,
        getLang("hour"),
        getLang("hours")
      );
      break;
    case WachtEenheid.Day:
      newDescription = getDescription(
        newInput,
        getLang("day"),
        getLang("days")
      );
      break;
    case WachtEenheid.Week:
      newDescription = getDescription(
        newInput,
        getLang("week"),
        getLang("weeks")
      );
      break;
    case WachtEenheid.Month:
      newDescription = getDescription(
        newInput,
        getLang("month"),
        getLang("months")
      );
      break;
    case WachtEenheid.Minute:
      newDescription = getDescription(
        newInput,
        getLang("minute"),
        getLang("minutes")
      );
      break;
    default:
      //days
      newDescription = getDescription(
        newInput,
        getLang("day"),
        getLang("days")
      );

      break;
  }

  return { Description: newDescription, Properties: newProperties };
};

export const getVoorwaardeFieldValues = function(
  properties: any,
  multiplevalues: boolean,
  field: string,
  options: any[]
): null | any[] {
  if (isEmpty(options)) return null;

  const model = (isEmpty(properties) ? null : properties) as null | any;
  if (multiplevalues) {
    let ids = !!model && !!model[field] ? [...(model[field] as any)] : [];
    if (ids.length > 0) {
      return ids
        .filter(
          (id) =>
            (options as any[]).filter((t) => String(t.Id) === String(id))
              .length > 0
        )
        .map((id) => {
          return (options as any[]).filter(
            (t) => String(t.Id) === String(id)
          )[0];
        });
    }
  } else {
    let id = !!model ? (model[field] as number | string) : null;

    const optie = (options as any[]).find((t) => String(t.Id) === String(id));

    if (!!optie) {
      return optie;
    }
  }

  return null;
};

export const smartTruncate = (string: string, length: number) => {
  const mark = "\u2026"; // ellipsis = …
  const position = length - 1; // ellipsis achteraan plaatsen

  const markOffset = mark.length;
  const minLength = 4;

  let str = string;

  if (typeof str === "string") {
    str = str.trim();
  }

  const invalid =
    typeof str !== "string" ||
    str.length < minLength ||
    typeof length !== "number" ||
    length <= minLength ||
    length >= str.length - markOffset;

  if (invalid) return string;

  if (position >= length - markOffset) {
    const start = str.substring(0, length - markOffset);
    return `${start}${mark}`;
  }

  const start = str.substring(0, position);
  const end = str.slice(position + markOffset - length);

  return `${start}${mark}${end}`;
};
