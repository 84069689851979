import { ActionContext } from "vuex";
import axios from "axios";
import { Autorisatie } from "../Autorisatie";

export interface INotificationStoreState {
  unreadUnsentMessageCount: number;
}

export default {
  namespaced: true,
  state: {
    unreadUnsentMessageCount: 0
  } as INotificationStoreState,

  mutations: {
    setUnreadUnsentMessageCount(state: INotificationStoreState, value: number) {
      state.unreadUnsentMessageCount = value;
    }
  },

  actions: {
    refreshNotifications(
      context: ActionContext<INotificationStoreState, INotificationStoreState>
    ) {
      if (!context.rootGetters['Identity/HasAutorisatie'](Autorisatie.BeheerBericht))
        return;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/bericht/v1/sentMessages/unreadcount")
          .then(response => {
            context.commit("setUnreadUnsentMessageCount", response.data);
            resolve(1);
          })
          .catch(err => {
            console.warn(
              "Fout bij ophalen ongelezen niet verzonden berichten: " + err
            );
            resolve(0);
          });
      });
    }
  },

  getters: {
    UnreadUnsentMessageCount(state: INotificationStoreState) {
      return state.unreadUnsentMessageCount;
    }
  }
};
