import { ActionContext } from "vuex";
import axios from "axios";
import * as jwt_decode from "jwt-decode";
import * as Cookies from "js-cookie";

// tslint:disable-next-line:interface-name
export interface RootState {
  version: string;
  jwt?: string;
  adminJwt?: string;
}


const JWTKey = "JWTMAX";
const AdminJWTKey = "JWTMAXAdmin";


export default {
  namespaced: true,
  state: {
    jwt: Cookies.get(JWTKey),
    adminJwt: Cookies.get(AdminJWTKey)
  },
  mutations: {
    updateToken(state: RootState, newToken: any) {
      Cookies.set(JWTKey, newToken, { expires: 1, path: "/" });
      state.jwt = newToken; 
    },

    updateAdminToken(state: RootState, newToken: any) {
      Cookies.set(AdminJWTKey, newToken, { expires: 1, path: "/" });
      state.adminJwt = newToken; 
    },

    updateTokensWithoutCookie(state: RootState, newTokens: any) {
      state.jwt = newTokens.jwt; 
      state.adminJwt = newTokens.adminJwt;
    }
  },
  actions: {
      checkRenewToken(context: ActionContext<RootState, RootState>) {
        // Reguliere token
        const hasToken = context.getters["hasUserToken"];
        if (hasToken && context.getters["userTokenIsValid"]()) {
          if (context.getters["userTokenIsOutdated"]()) {
            console.info("JWT is at least 15 minutes old, renewing");
            context.dispatch("renewToken");
          }
          else
            console.info("JWT is still good");          
        }
        else
          console.warn("JWT is not valid");


        // Admin token (voor impersonation)
        const hasAdminToken = context.getters["hasAdminToken"];
        if (hasAdminToken && context.getters["adminTokenIsValid"]()) {
          if (context.getters["adminTokenIsOutdated"]()) {
            console.info("Admin JWT is at least 15 minutes old, renewing");
            context.dispatch("renewAdminToken");
          }
          else
            console.info("Admin JWT is still good");          
        }
      },

      renewToken(context : ActionContext<RootState, RootState>) {
        // jwt wordt door axios.interceptors toegevoegd, maar dat is potentieel de admin
        // JWT, dus specifiek meegeven.
        axios.post("/api/maxidentity/v1/identity/renewtoken", {
          token: context.state.jwt
        }).then(response => {
          if (!!response.data && !!response.data.token) {
            context.commit("updateToken", response.data.token);
            console.info("JWT succesfully renewed");
          }
        }).catch(error =>
        {
          console.error("Renewing JWT failed");
          console.error(error); // als 3x niet lukt naar logout pagina?
        })
      },

      renewAdminToken(context : ActionContext<RootState, RootState>) {
        axios.post("/api/maxidentity/v1/identity/renewtoken", {
          token: context.state.adminJwt
        }).then(response => {
          if (!!response.data && !!response.data.token) {
            context.commit("updateAdminToken", response.data.token);
            console.info("Admin JWT succesfully renewed");
          }
        }).catch(error =>
        {
          console.error("Renewing Admin JWT failed");
          console.error(error); // als 3x niet lukt naar logout pagina?
        })
      },


      refreshFromCookies(context : ActionContext<RootState, RootState>)
      {
        context.commit("updateTokensWithoutCookie", { jwt: Cookies.get(JWTKey), adminJwt: Cookies.get(AdminJWTKey) });
      }  
  },
  getters: {
    JWT(state: RootState) {
      return !!state.adminJwt ? state.adminJwt : state.jwt;
    },
    HasAutorisatie(state: RootState, getters: any) {
      return (autorisatie: number) => {
        const jwt = getters["JWT"];
        if (!!jwt) {
          var decoded: any = jwt_decode(jwt);        
          var aut = decoded.aut;

          var actAutorisaties = aut.split(",").filter(function(item : string) {
            var app = item.substring(0, item.indexOf("-"));
            if (app === "2") {
            return true;
            }
            return false;
          }).map(function(item : string) {
            var nr = item.substring(item.indexOf("-") + 1);
            return parseInt(nr, 10);
          });

          return actAutorisaties.includes(autorisatie) || decoded.adm;
        }
        return false;
      };
    },
    HasFeature(state: RootState, getters: any) {
      // Feature is een string omdat sourceservice hier altijd relevant is.
      // Het gaat namelijk om de uitgever van de feature niet de applicatie.
      return (feature: string) => {
        const jwt = getters["JWT"];
        if (!!jwt) {
          var decoded: any = jwt_decode(jwt);
          var feat = decoded.feat;

          return !!feat && feat.split(",").includes(feature);
        }
        return false;
      }
    },
    IsAdmin(state: RootState, getters: any) {
      const jwt = getters["JWT"];
      if (!!jwt) {
        var decoded: any = jwt_decode(jwt);
        return decoded.adm;
      }
      return false;
    },
    Language(state: RootState, getters: any) {
      const jwt = getters["JWT"];
      if (!!jwt) {
        var decoded: any = jwt_decode(jwt);
        return decoded.lng.substring(0,2);
      }
      return null;
    },
    AutobedrijfID(state: RootState, getters: any) {
      const jwt = getters["JWT"];
      if (!!jwt) {
        var decoded: any = jwt_decode(jwt);
        return decoded.bdrid;
      }
      return null;
    },
    AutobedrijfNaam(state: RootState, getters: any) {
      const jwt = getters["JWT"];
      if (!!jwt) {
        var decoded: any = jwt_decode(jwt);
        return decoded.bdrn;
      }
      return null;
    },
    UserID(state: RootState, getters: any) {
      const jwt = getters["JWT"];
      if (!!jwt) {
        var decoded: any = jwt_decode(jwt);
        return decoded.usr;
      }
      return null;
    },
    UserNaam(state: RootState, getters: any) {
      const jwt = getters["JWT"];
      if (!!jwt) {
        var decoded: any = jwt_decode(jwt);
        return decoded.usn;
      }
      return null;
    },

    // Onderstaande methodes zijn "method style" omdat getters default cached zijn en wel
    // reactive op andere reactive properties (zoals jwt) maar niet op de Date.now die
    // in deze methods gebruikt wordt. Oftewel; het resultaat kan veranderen ook al is de state
    // niet gewijzigd.
    userTokenIsValid(state: RootState) {
      const extraAllowedOvertime = 300 * 60;
      return () => {
        const jwt = state.jwt;
        if (!!jwt) {
          const decoded: any = jwt_decode(jwt);
          const current_time = Math.floor(Date.now() / 1000);

          return (decoded.exp + extraAllowedOvertime) >= current_time && !!decoded.aut;
        }
        return false;
      };
    },

    userTokenIsOutdated(state: RootState, getters: any) {
      return () => {
        const jwt = getters["JWT"];
        if (!!jwt) {
          const decoded: any = jwt_decode(jwt);
          const current_time = Math.floor(Date.now() / 1000);
          const renewAfter = 15 * 60;  // 15 min

          return decoded.iat + renewAfter <= current_time;
        }
        return false;
      };
    },

    hasUserToken(state: RootState) {
      return !!state.jwt;
    },

    adminTokenIsValid(state: RootState) {
      const extraAllowedOvertime = 300 * 60;
      return () => {
        const jwt = state.adminJwt;
        if (!!jwt) {
          const decoded: any = jwt_decode(jwt);
          const current_time = Math.floor(Date.now() / 1000);

          return (decoded.exp + extraAllowedOvertime) >= current_time && !!decoded.aut;
        }
        return false;
      };
    },
    adminTokenIsOutdated(state: RootState) {
      return () => {
        const jwt = state.adminJwt;
        if (!!jwt) {
          const decoded: any = jwt_decode(jwt);
          const current_time = Math.floor(Date.now() / 1000);
          const renewAfter = 15 * 60;  // 15 min

          return decoded.iat + renewAfter <= current_time;
        }
        return false;
      };
    },

    hasAdminToken(state: RootState) {
      return !!state.adminJwt;
    }    
  }
};
