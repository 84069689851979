export enum Autorisatie {
  GebruikerInloggen = 1,
  BeheerBericht = 3,
  BeheerWorkflow = 4,
  Dashboard = 5,
  Rapportage = 6,
  BeheerDoelgroep = 7,
  BeheerSiteTracking = 9
}

