import router from "../router";
import { EventBus } from "./EventBus";


// Voor nu gebruik maken van IBeheer omdat deze de gewenste functionaliteit dekt.
// Hier willen we waarschijnlijk een andere methodiek voor gaan gebruiken, maar
// laten we even de experimenten met single-SPA en/of module federation afwachten.
export function implementBeheerInterface()
{
  const writableWindow = window as any;
  writableWindow.beheer = {
    goHome,
    notify
  }
}



function goHome(): void
{
  router.push({ name: 'home' });
}


function notify(message: string, type?: string): void
{
  EventBus.$emit("add-toastr", {
    text: message,
    type: type || "info"
  });
}
