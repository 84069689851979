import { ActionContext } from "vuex";
import axios from "axios";

export interface IAnnouncementStoreState {
  release: Date | null;
  showRelease: boolean;
}

interface ReleaseNotesDateViewModel {
  Date: string;
}

export default {
  namespaced: true,
  state: {
    release: null,
    showRelease: false
  } as IAnnouncementStoreState,

  mutations: {
    setRelease(state: IAnnouncementStoreState, value: string | Date) {
      let newValue = null;
      try {
        if (Object.prototype.toString.call(value) === "[object Date]")
          newValue = value as Date;
        else if (typeof value === "string" || value instanceof String) {
          newValue = new Date(value);
        }
      } catch (err) {}

      state.release = newValue;
    },
    setShowRelease(state: IAnnouncementStoreState, value: boolean) {
      if (state.showRelease !== value) state.showRelease = value;
    }
  },

  actions: {
    refreshReleaseDate(
      context: ActionContext<IAnnouncementStoreState, IAnnouncementStoreState>
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get<ReleaseNotesDateViewModel>("/api/announcement/v1/releasenote")
          .then(response => {
            context.commit("setRelease", response.data.Date);
            resolve();
          })
          .catch(err => {
            console.warn("Fout bij ophalen releasenote: " + err);
            resolve();
          });
      });
    },

    readNote(
      context: ActionContext<IAnnouncementStoreState, IAnnouncementStoreState>
    ) {
      axios
        .post(
          "/api/announcement/v1/releasenote/read",
          {},
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then(() => {
          context.commit("setRelease", null);
          context.commit("setShowRelease", false);
        })
        .catch(err => {
          console.warn("Fout bij gelezen releasenote: " + err);
        });
    }
  },

  getters: {
    showRelease(state: IAnnouncementStoreState) {
      return state.showRelease;
    },
    // Onderstaande methode is "method style" omdat getters default cached zijn.
    // Oftewel; het resultaat kan veranderen ook al is de state niet gewijzigd.
    showAnnouncement(state: IAnnouncementStoreState) {
      return () => {
        if (!!state.release) {
          return new Date() > (state.release as Date);
        }
        return false;
      };
    }
  }
};
