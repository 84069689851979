







































































































import Vue from "vue";
import IconMessage from "../components/reversed/IconMessage.vue";
import IconActieveKlant from "../components/reversed/IconActieveKlant.vue";
import IconVoertuigbronnen from "../components/reversed/IconVoertuigbronnen.vue";
import IconWebtracking from "../components/reversed/IconWebtracking.vue";
import IconWerkplaatsplanner from "../components/reversed/IconWerkplaatsplanner.vue";
import IconFlows from "../components/reversed/IconFlows.vue";
import GaugeFull from "vue-material-design-icons/GaugeFull.vue";
import TargetAccount from "vue-material-design-icons/TargetAccount.vue"
import IconLEF from "../components/reversed/IconLEF.vue";
import { Autorisatie } from "../Autorisatie";
import { Feature } from "../Feature"

export default Vue.extend({
  components: {
    IconMessage,
    IconActieveKlant,
    IconVoertuigbronnen,
    IconWebtracking,
    IconWerkplaatsplanner,
    IconFlows,
    GaugeFull,
    IconLEF,
    TargetAccount
  },
  data() {
    return {
      Autorisatie: Autorisatie,
      Feature: Feature
    };
  },
  methods: {
    hasAutorisatie(autorisatie: number) {
      return this.$store.getters["Identity/HasAutorisatie"](autorisatie);
    },
    hasAutobedrijf() {
      return !!this.$store.getters["Identity/AutobedrijfID"];
    },
    isAdmin(): boolean {
      return this.$store.getters["Identity/IsAdmin"];
    },
    hasFeature(feature: Feature): boolean {
      return this.$store.getters["Identity/HasFeature"](feature);
    },
  },
});
