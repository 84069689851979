// src/index.ts
import "@babel/polyfill";
import "./lib/classList.js";
import "./lib/closest.js";
import "./lib/path-data-polyfill.js";
import "./lib/padStart.js";
import "./lib/padEnd.js";
import Vue from "vue";
import VueI18n from "vue-i18n";
import axios, { AxiosRequestConfig } from "axios";
import App from "./App.vue";
import messages from "./lang";
import Vuex from "vuex";
import IdentityStore from "./stores/identity";
import NotificationsStore from "./stores/notifications";
import EnvironmentStore from "./stores/environment";
import AnnouncementStore from "./stores/announcements";
import router from "./router";
import Notifications from "./notifications";
import Announcements from "./announcements";
import LoginMonitor from "./loginMonitor";
import { implementBeheerInterface } from "./lib/beheerImpl";

Vue.use(VueI18n);
Vue.use(Vuex);

const store: any = new Vuex.Store<any>({
  modules: {
    Identity: IdentityStore,
    Notifications: NotificationsStore,
    Environment: EnvironmentStore,
    Announcements: AnnouncementStore,
  },
});

const i18n = new VueI18n({
  locale: "nl",
  fallbackLocale: "nl",
  messages: messages,
});

function redirectLogin(toUrl: string) 
{
  window.location.href = "/api/maxidentity/v1/renew?returnUrl=" + encodeURIComponent(toUrl);
};

router.beforeEach((to, from, next) => {
  const hasToken = store.getters["Identity/hasUserToken"];
  if (hasToken && store.getters["Identity/userTokenIsValid"]()) {
    next();
  } else {
    redirectLogin(to.path);
    // no next
  }
});

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.url !== undefined && config.url.indexOf("loginurl") === -1) {
      config.headers["Authorization"] =
        "Bearer " + store.getters["Identity/JWT"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error: any) => {
  if (error.response && error.response.status === 401) {
    window.location.href = "/api/maxidentity/v1/logout";
  }
  return Promise.reject(error);
});


setInterval(() =>
{
  store.dispatch("Identity/checkRenewToken");
}, 60000);


Notifications.observe(store);
Announcements.observe(store);
LoginMonitor.observe(store);

new Vue({
  el: "#app",
  store,
  i18n,
  router,
  render: (h) => h(App),
});

export { i18n };


implementBeheerInterface();