import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home.vue";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    { 
      name: "home",
      path: "/", 
      component: Home 
    },
    {
      name: "berichten",
      path: "/berichten/:id?",
      component: () => import("../pages/sjablonen/SjablonenContainer.vue")
    },
    {
      name: "berichtCopy",
      path: "/berichten/copy/:id?",
      component: () => import("../pages/sjablonen/SjablonenContainer.vue")
    },
    {
      name: "berichtentemplates",
      path: "/berichten/template/:id(\\d+)?",
      component: () => import("../pages/sjablonen/SjablonenContainer.vue")
    },
    {
      name: "workflows",
      path: "/workflows",
      component: () => import("../pages/workflows/WorkflowsIndex.vue")
    },
    {
      name: "workflowDetails",
      path: "/workflow/:workflowtype/:id",
      props: true,
      component: () => import("../pages/workflows/WorkflowDetails.vue")
    },
    {
      name: "campagneDetailsReadOnly",
      path: "/workflow/:workflowtype/ro/:id",
      props: true,
      component: () => import("../pages/workflows/CampagneDetails.vue")
    },
    {
      name: "workflowCopy",
      path: "/workflow/copy/:workflowtype/:id",
      props: true,
      component: () => import("../pages/workflows/WorkflowDetails.vue")
    },
    {
      name: "rapportages",
      path: "/rapportages/",
      component: () => import("../pages/rapportage/Rapportages.vue")
    },
    {
      name: "sentmessages",
      path: "/sentmessages/",
      component: () => import("../pages/sentMessages/SentMessages.vue")
    },
    {
      name: "dashboard",
      path: "/dashboard/",
      component: () => import("../pages/dashboard/Dashboard.vue")
    },
    {
      name: "drilldown",
      path:
        "/dashboard/drilldown/:workflowid/t/:widgettype/rt/:widgettypeindex/fr/:from/to/:to/v/:vestigingen/m/:merken/s/:soortautos",
      props: true,
      component: () => import("../pages/dashboard/Drilldown.vue")
    },
    {
      name: "doelgroepen",
      path: "/doelgroepen/",
      component: () => import("../pages/doelgroepen/DoelgroepIndex.vue"),
      meta: {
        fullSizeApp: true
      }
    },
    {
      name: "actieveklant",
      path: "/beheer/actieveklant",
      component: () => import("../pages/beheer/ActieveKlantIndex.vue")
    },
    {
      name: "volgendonderhound",
      path: "/beheer/volgendonderhoud",
      component: () => import("../pages/beheer/VolgendOnderhoudIndex.vue")
    },
    {
      name: "werkplaatsplanner",
      path: "/beheer/werkplaatsplanner",
      component: () => import("../pages/beheer/WerkplaatsplannerIndex.vue")
    },
    {
      name: "factuursoorten",
      path: "/beheer/factuursoorten",
      component: () => import("../pages/beheer/FactuursoortenIndex.vue")
    },
    {
      name: "webtracking",
      path: "/beheer/webtracking",
      component: () => import("../pages/beheer/WebTracking.vue")
    },
    {
      name: "voertuigbronnen",
      path: "/beheer/voertuigbronnen",
      component: () => import("../pages/beheer/VoertuigbronnenIndex.vue")
    },
    // {
    //   name: "test",
    //   path: "/test/",
    //   component: () => import("../pages/Test.vue")
    // },

    { path: "*", redirect: "/" }
  ]
});
