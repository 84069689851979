










export default {
  name: "actieve-klant-icon",
  props: {
    title: {
      type: String,
      default: "Actieve klant icon"
    },
    decorative: {
      type: Boolean,
      default: false
    }
  }
}
