











export default {
  name: "werkplaatsplanner-icon",
  props: {
    title: {
      type: String,
      default: "Werkplaatsplanner icon"
    },
    decorative: {
      type: Boolean,
      default: false
    }
  }
}
