













import Vue from "vue";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";

export default Vue.extend({
  props: {
    spanClasses: String,
    classes: String,
    buttonText: String,
    variant: String,
    id: [Number, String],
    to: String,
    params: {
      type: Object,
      default: () => {},
    },
    pressed: {
      type: Boolean,
      default: null,
    },
  },

  methods: {
    onButtonClick() {
      if (!!this.to) {
        let params =  { ...this.params} ;
        if (isNumber(this.id) || isString(this.id))
          params.id = String(this.id);

        this.$router.push({ name: this.to, params: params });
      } else this.$emit("clicked", this.id);
    },
  },

  computed: {
    useCSSClass(): string {
      let classNames = this.classes;
      let arrayClasses = ["btn"] as string[];
      arrayClasses.push("btn-" + this.variant);
      if (!!classNames) {
        arrayClasses = [...arrayClasses, ...classNames.split(" ")];
      }

      return arrayClasses.join(" ");
    },
  },
});
