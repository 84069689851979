






























import Vue from "vue";
import axios from "axios";
import ButtonAction from "../components/ButtonAction.vue";

interface ReleaseNoteURLViewModel {
  Url: string;
}

export default Vue.extend({
  components: {
    ButtonAction,
  },
  methods: {
    onReadNotesClick(): void {
      axios
        .get<ReleaseNoteURLViewModel>("/api/announcement/v1/releasenote/url")
        .then((response) => {
          if (!!response.data.Url) {
            this.onCloseClick();
            window.open(response.data.Url, "_blank");
          }
        })
        .catch((err) => {});
    },
    onCloseClick(): void {
      this.$store.dispatch("Announcements/readNote");
    },
  },
});
