import en from "./locale/en";
import nl from "./locale/nl";
import de from "./locale/de";
import fr from "./locale/fr";

export default {
  en: en,
  nl: nl,
  de: de,
  fr: fr
};