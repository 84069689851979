export default {
  observe(store: any) 
  {
    // Note: dit werkt niet perfect. Als je direct naar MAX gaat en inlogt als admin dan heb je geen impersonation token
    // en kom je op een MAX die niets doet. Dan werkt deze logout check niet. Omdat het zo specifiek is en je toch niet
    // op die pagina blijft hangen is het good enough nu.

    // Huidige state capturen
    // Dit zou eigenlijk in de store moeten gebeuren, maar de manier waarop de store werkt en wordt aangestuurd is toch
    // al een zooitje. Refactoren zodra we over gaan op de Composition API, dan kan het veel mooier.
    let lastCookies = document.cookie;
    let expectedBedrijfID = store.getters["Identity/AutobedrijfID"];


    function checkAutobedrijfID()
    {
      const bedrijfID = store.getters["Identity/AutobedrijfID"];
      if (expectedBedrijfID === null)
      {
        console.log("JWT gezet, wijzigingen worden gemonitored");
        if (bedrijfID !== null)
          expectedBedrijfID = bedrijfID;
      } 
      else
      {
        if (bedrijfID === null)
        {
          console.log("JWT leeggemaakt, terugsturen naar login pagina's");
          sessionStorage.clear();
          window.location.href = '/api/maxidentity/v1/loggedout';
          return;
        }


        if (bedrijfID !== expectedBedrijfID)
        {
          console.log("JWT gebruiker of bedrijf gewijzigd, doorsturen naar login pagina met melding");
          sessionStorage.clear();

          // Niet naar logout redirecten want die cleared de cookie terwijl je net in de andere tab ingelogt bent,
          // dan wordt je meteen in beide tabs er uit gekickt, heel irritant. We zouden naar de landing page kunnen
          // gaan, maar ik heb gekozen om een nieuwe page te maken in de login pagina die specifiek dit issue vermeld.
          window.location.href = '/api/maxidentity/v1/loginchanged';
        }
      }      
    }


    store.watch(
      (state: any, getters: any) => getters["Identity/JWT"],
      (newValue: boolean) => {
        checkAutobedrijfID();
      }
    );


    console.log("Cookies worden gemonitored");
    window.setInterval(() =>
    {
      // Eerst een snelle check op de cookies, dan kunnen we deze method vaker draaien
      // zonder de hele tijd de JWT te hoeven decoden. Niet dat dit bijzonder veel impact heeft,
      // maar het voelt anders niet goed...
      // Er is een CookieStore met change event in Chrome, maar dat is nog geen standaard.
      if (document.cookie === lastCookies)
        return;

      console.log("Cookies gewijzigd, JWT wordt gecontroleerd");
      lastCookies = document.cookie;

      store.dispatch('Identity/refreshFromCookies');
    }, 500);
  }
}
