import Vue from "vue";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { newId } from "./WorkflowUtils";
import {
  WorkflowDetailsViewModel,
  WorkflowDetailsDataViewModel,
  WorkflowDetailStepStartViewModel,
  WorkflowDetailStepViewModel,
  WorkflowDetailStepLEFViewModel,
  WorkflowDetailStepBerichtViewModel,
  WorkflowDetailStepWachtViewModel,
  WorkflowDetailStepEindeViewModel,
  WorkflowDetailStepVoorwaardeViewModel,
  WorkflowDetailStepCombinatieVoorwaardeViewModel,
  WorkflowStartPrerequisiteViewModel,
  WorkflowDetailConditionViewModel,
  WorkflowDetailsCampagneDataViewModel,
} from "../../TypeScriptModels";

import {
  IWorkflow,
  IWorkflowNode,
  WorkflowNodeType,
  IBaseNodeProperties,
  IWorkflowNodePrerequisites,
  IWorkflowNodeConditions,
  IVoorwaardeNodeProperties,
  ICombinatieVoorwaardeNodeProperties,
  IWorkflowNodeDictionary,
  IBerichtNodeProperties,
  IWachtNodeProperties,
  IEindeNodeProperties,
  ILEFNodeProperties,
  IStartNodeProperties,
  WorkflowEditorViewModel,
  ICampagneData,
  StartTriggerType,
  Categorie,
} from "./IWorkflow";

export default class WorkflowConverter {
  newWorkflow(startTriggerId: Number | null): WorkflowEditorViewModel {
    const startid = newId();
    let startStep = {
      Id: startid,
      StepTypeId: WorkflowNodeType.Start,
      Description: "",
      Properties: {
        Id: startTriggerId,
        TimeUnit: null,
        TimeValue: null,
        IsTimedStart: false,
        Garantie: null,
        Doelgroepen: null,
        StartMoment: null,
        StartTijd: null,
        AantalPerDag: null,
        StartEntiteit: null,
      },
      Prerequisites: [],
      Children: [
        {
          Id: newId(),
          ParentStepId: startid,
          StepTypeId: WorkflowNodeType.Placeholder,
          Children: [],
        },
      ],
      Conditions: [],
    } as IWorkflowNode;

    return this.withDataReactive({
      Id: "00000000-0000-0000-0000-000000000000",
      Titel: null,
      Omschrijving: null,
      Categorie:
        startTriggerId === StartTriggerType.Campagne
          ? { Id: Categorie.Campagne, Omschrijving: "Campagne" }
          : null,
      Labels: [],
      Laatstgewijzigd: null,
      Actief: false,
      Data: {
        Root: startStep,
        CampagneData: {
          AantalPerDag: null,
          StartMoment: null,
          Doelgroepen: null,
          StartEntiteit: null,
          StartTijd: null,
        },
        BerichtAccorderen: startTriggerId !== StartTriggerType.Campagne,
        HasBerichtStep: false,
        doelgroepLookup: null,
      },
      Versie: 1,
      MultipleExecutions: false,
      ActieveStoppen: false,
      BerichtAccorderen: startTriggerId !== StartTriggerType.Campagne,
    });
  }

  private withDataReactive(
    workflow: WorkflowEditorViewModel
  ): WorkflowEditorViewModel {
    const hasNode = this.hasNode.bind(this);

    workflow.Data = new Vue({
      data: {
        Root: workflow.Data.Root,
        CampagneData: workflow.Data.CampagneData,
      },
      computed: {
        BerichtAccorderen(): boolean {
          return workflow.BerichtAccorderen;
        },
        HasBerichtStep(): boolean {
          return hasNode(this.Root, WorkflowNodeType.Bericht);
        },
      },
    }) as IWorkflow;

    return workflow;
  }

  toWorkflowEditorViewModel(
    model: WorkflowDetailsViewModel
  ): WorkflowEditorViewModel {
    return this.withDataReactive({
      Id: model.Id,
      Titel: model.Titel,
      Omschrijving: model.Omschrijving,
      Categorie: model.Categorie,
      Labels: model.Labels,
      Laatstgewijzigd: model.Laatstgewijzigd,
      Actief: model.Actief,
      Data: {
        Root: this.toWorkflowStartStep(model.Data),
        BerichtAccorderen: model.BerichtAccorderen,
        HasBerichtStep: false,
        CampagneData: this.toWorkflowCampagneData(model.Data.CampagneData),
        doelgroepLookup: null,
      },
      Versie: model.Versie,
      MultipleExecutions: model.MultipleExecutions,
      ActieveStoppen: model.ActieveStoppen,
      BerichtAccorderen: model.BerichtAccorderen,
    });
  }

  toWorkflowCampagneData(
    model: WorkflowDetailsCampagneDataViewModel | null
  ): ICampagneData {
    if (!!model)
      return {
        AantalPerDag: !!model.AantalPerDag ? model.AantalPerDag : null,
        StartMoment: !!model.StartMoment ? model.StartMoment : null,
        StartTijd: !!model.StartTijd ? model.StartTijd : null,
        Doelgroepen: !!model.Doelgroepen ? model.Doelgroepen : null,
        StartEntiteit: !!model.StartEntiteit ? model.StartEntiteit : null,
      } as ICampagneData;

    return {
      AantalPerDag: null,
      StartMoment: null,
      StartTijd: null,
      Doelgroepen: null,
      StartEntiteit: null,
    };
  }

  fromWorkflowEditorViewModel(
    model: WorkflowEditorViewModel
  ): WorkflowDetailsViewModel {
    return {
      Id: model.Id,
      Titel: model.Titel,
      Omschrijving: model.Omschrijving,
      Categorie: model.Categorie,
      Labels: model.Labels,
      Laatstgewijzigd: model.Laatstgewijzigd,
      Actief: model.Actief,
      Readonly: false,
      Data: this.fromWorkflow(model.Data),
      Versie: model.Versie,
      MultipleExecutions: model.MultipleExecutions,
      ActieveStoppen: model.ActieveStoppen,
      BerichtAccorderen: model.BerichtAccorderen,
    };
  }

  toWorkflowStartStep(viewModel: WorkflowDetailsDataViewModel): IWorkflowNode {
    const stepStart = this.getStart(viewModel);

    const startprop = stepStart.Properties as WorkflowDetailStepStartViewModel;
    return {
      Id: stepStart.Id,
      StepTypeId: WorkflowNodeType.Start,
      Description: stepStart.Description,
      Properties: {
        Id: startprop.Id,
        IsTimedStart: startprop.IsTimedStart,
        TimeUnit: startprop.TimeUnit,
        TimeValue: startprop.TimeValue,
        Garantie: startprop.Garantie,
        StartMoment: startprop.StartMoment,
        StartTijd: startprop.StartTijd,
        AantalPerDag: startprop.AantalPerDag,
        Doelgroepen: startprop.Doelgroepen,
        StartEntiteit: startprop.StartEntiteit,
      },
      Prerequisites: this.toPrerequisites(stepStart),
      Children: this.createChildNodes(stepStart.Id, viewModel),
      Conditions: [],
    } as IWorkflowNode;
  }

  fromWorkflow(workflow: IWorkflow): WorkflowDetailsDataViewModel {
    if (isEmpty(workflow))
      return {
        Steps: [],
        CampagneData: null,
      };

    let steps = [];
    let startstep = this.fromNode(workflow.Root);

    steps.push(startstep);

    const startProps = startstep.Properties as WorkflowDetailStepStartViewModel;
    let campagneData = null;
    if (
      !!startProps.StartMoment ||
      !!startProps.StartTijd ||
      !!startProps.Doelgroepen ||
      !!startProps.AantalPerDag ||
      !!startProps.StartEntiteit
    )
      campagneData = {
        StartMoment: !!startProps.StartMoment ? startProps.StartMoment : null,
        StartTijd: !!startProps.StartTijd ? startProps.StartTijd : null,
        Doelgroepen: !!startProps.Doelgroepen ? startProps.Doelgroepen : null,
        AantalPerDag: !!startProps.AantalPerDag
          ? startProps.AantalPerDag
          : null,
        StartEntiteit: !!startProps.StartEntiteit
          ? startProps.StartEntiteit
          : null,
      };

    steps = this.addChildSteps(workflow.Root, steps);
    return {
      Steps: steps,
      CampagneData: campagneData,
    };
  }

  public static toDictionary(workflow: IWorkflow): IWorkflowNodeDictionary {
    if (!!workflow && !!workflow.Root) {
      const dict = WorkflowConverter.flatten(workflow.Root, {});

      // Voor nog niet gestarte workflow executions in campagnes is de stepID empty GUID
      dict["00000000-0000-0000-0000-000000000000"] = workflow.Root;

      return dict;
    }
    return {};
  }

  private fromNode(node: IWorkflowNode): WorkflowDetailStepViewModel {
    return {
      Id: node.Id,
      ParentStepId: node.ParentStepId,
      StepType: this.mapFromNodeType(node.StepTypeId),
      Description: node.Description,
      Properties: this.fromProperties(node.StepTypeId, node.Properties || {}),
      Prerequisites:
        node.StepTypeId !== WorkflowNodeType.Start
          ? []
          : this.fromPrerequisites(node),
      Conditions:
        node.StepTypeId !== WorkflowNodeType.Voorwaarde
          ? []
          : this.fromConditions(node),
    };
  }

  public static flatten(
    node: IWorkflowNode,
    dict: IWorkflowNodeDictionary
  ): IWorkflowNodeDictionary {
    dict[get(node, "Id", "") as string] = node;

    get(node, "Children", []).forEach((element) => {
      WorkflowConverter.flatten(element, dict);
    });
    return dict;
  }

  private getStart(
    viewModel: WorkflowDetailsDataViewModel
  ): WorkflowDetailStepViewModel {
    const start = viewModel.Steps.find((s) => s.StepType === "Start");
    if (!start) throw "Startstep not found!";
    return start;
  }

  private findStep(
    id: string,
    viewModel: WorkflowDetailsDataViewModel
  ): WorkflowDetailStepViewModel | null {
    const step = viewModel.Steps.find((s) => s.Id === id);
    return !!step ? step : null;
  }

  private hasNode(startnode: IWorkflowNode, nodeType: WorkflowNodeType) {
    if (startnode.StepTypeId === nodeType) return true;

    for (let index = 0; index < startnode.Children.length; index++) {
      const element = startnode.Children[index];
      if (this.hasNode(element, nodeType)) return true;
    }
    return false;
  }

  //bij voorwaarde zijn er altijd 2, want er is altijd een placeholder als fallback
  private findChildSteps(
    id: string,
    viewModel: WorkflowDetailsDataViewModel
  ): WorkflowDetailStepViewModel[] {
    return viewModel.Steps.filter((s) => s.ParentStepId === id);
  }

  private mapToNodeType(nodetype: string): WorkflowNodeType {
    switch (nodetype) {
      case "Placeholder":
        return WorkflowNodeType.Placeholder;
      case "Start":
        return WorkflowNodeType.Start;
      case "Bericht":
        return WorkflowNodeType.Bericht;
      case "LEF":
        return WorkflowNodeType.LEF;
      case "Voorwaarde":
        return WorkflowNodeType.Voorwaarde;
      case "Wacht":
        return WorkflowNodeType.Wacht;
      case "Einde":
        return WorkflowNodeType.Einde;
    }
    return WorkflowNodeType.Placeholder;
  }

  private mapFromNodeType(nodetype: WorkflowNodeType): string {
    switch (nodetype) {
      case WorkflowNodeType.Placeholder:
        return "Placeholder";
      case WorkflowNodeType.Start:
        return "Start";
      case WorkflowNodeType.Bericht:
        return "Bericht";
      case WorkflowNodeType.LEF:
        return "LEF";
      case WorkflowNodeType.Voorwaarde:
        return "Voorwaarde";
      case WorkflowNodeType.Wacht:
        return "Wacht";
      case WorkflowNodeType.Einde:
        return "Einde";
    }
    return "Placeholder";
  }

  private toProperties(step: WorkflowDetailStepViewModel): IBaseNodeProperties {
    switch (this.mapToNodeType(step.StepType)) {
      case WorkflowNodeType.Start:
        {
          const prop = step.Properties as WorkflowDetailStepStartViewModel;
          return {
            Id: prop.Id,
            TimeUnit: prop.TimeUnit,
            TimeValue: prop.TimeValue,
            IsTimedStart: prop.IsTimedStart,
            Garantie: prop.Garantie,
          };
        }
        break;
      case WorkflowNodeType.LEF:
        {
          const prop = step.Properties as WorkflowDetailStepLEFViewModel;
          return {
            Id: prop.Id,
            LeadType: prop.LeadType,
            SoortLead: prop.SoortLead,
            LeadInitiatief: prop.LeadInitiatief,
          };
        }
        break;
      case WorkflowNodeType.Bericht:
        {
          const prop = step.Properties as WorkflowDetailStepBerichtViewModel;
          return {
            BerichtId: prop.BerichtId,
            BerichtTypeOrder: prop.BerichtTypeOrder,
          };
        }
        break;
      case WorkflowNodeType.Wacht:
        {
          const prop = step.Properties as WorkflowDetailStepWachtViewModel;
          return {
            TimeValue: prop.TimeValue,
            TimeUnit: prop.TimeUnit,
          };
        }
        break;
      case WorkflowNodeType.Einde:
        {
          const prop = step.Properties as WorkflowDetailStepEindeViewModel;
          return {
            Id: prop.Id,
          };
        }
        break;
      case WorkflowNodeType.Voorwaarde:
        {
          const prop = step.Properties as WorkflowDetailStepCombinatieVoorwaardeViewModel;
          return this.toCombinatieVoorwaardeNodeProperties(prop);
        }
        break;
      default:
        break;
    }
    return {};
  }

  private fromProperties(
    nodetype: WorkflowNodeType,
    baseproperty: IBaseNodeProperties
  ):
    | WorkflowDetailStepStartViewModel
    | WorkflowDetailStepBerichtViewModel
    | WorkflowDetailStepWachtViewModel
    | WorkflowDetailStepVoorwaardeViewModel
    | WorkflowDetailStepCombinatieVoorwaardeViewModel
    | WorkflowDetailStepEindeViewModel
    | WorkflowDetailStepLEFViewModel
    | null {
    switch (nodetype) {
      case WorkflowNodeType.Bericht:
        {
          const prop = baseproperty as IBerichtNodeProperties;
          return {
            BerichtId: prop.BerichtId,
            BerichtTypeOrder: prop.BerichtTypeOrder,
          };
        }
        break;
      case WorkflowNodeType.Wacht:
        {
          const prop = baseproperty as IWachtNodeProperties;
          return {
            TimeValue: prop.TimeValue,
            TimeUnit: prop.TimeUnit,
          };
        }
        break;
      case WorkflowNodeType.Einde:
        {
          const prop = baseproperty as IEindeNodeProperties;
          return {
            Id: prop.Id,
          };
        }
        break;
      case WorkflowNodeType.LEF:
        {
          const prop = baseproperty as ILEFNodeProperties;
          return {
            Id: prop.Id,
            LeadType: prop.LeadType,
            SoortLead: prop.SoortLead,
            LeadInitiatief: prop.LeadInitiatief,
          };
        }
        break;
      case WorkflowNodeType.Start:
        {
          const prop = baseproperty as IStartNodeProperties;
          return {
            Id: prop.Id,
            TimeValue: prop.TimeValue,
            TimeUnit: prop.TimeUnit,
            IsTimedStart: prop.IsTimedStart,
            Garantie: prop.Garantie,
            StartMoment: prop.StartMoment,
            StartTijd: prop.StartTijd,
            Doelgroepen: prop.Doelgroepen,
            AantalPerDag: prop.AantalPerDag,
            StartEntiteit: prop.StartEntiteit,
          };
        }
        break;
      case WorkflowNodeType.Voorwaarde:
        {
          const prop = baseproperty as ICombinatieVoorwaardeNodeProperties;
          return this.fromCombinatieVoorwaardeNodeProperties(prop);
        }
        break;

      default:
        break;
    }

    return null;
  }

  private toCombinatieVoorwaardeNodeProperties(
    prop: WorkflowDetailStepCombinatieVoorwaardeViewModel | null | undefined
  ): ICombinatieVoorwaardeNodeProperties {
    if (isEmpty(prop))
      return {
        Id: null,
        Description: null,
        Operator: null,
      };
    return {
      Id: prop!.Id,
      Description: prop!.Description,
      Operator: prop!.Operator,
    };
  }

  private fromCombinatieVoorwaardeNodeProperties(
    prop: ICombinatieVoorwaardeNodeProperties | null | undefined
  ): WorkflowDetailStepCombinatieVoorwaardeViewModel {
    if (isEmpty(prop))
      return {
        Id: null,
        Description: null,
        Operator: null,
      };
    return {
      Id: prop!.Id,
      Description: prop!.Description,
      Operator: prop!.Operator,
    };
  }

  private toVoorwaardeNodeProperties(
    prop: WorkflowDetailStepVoorwaardeViewModel | null | undefined
  ): IVoorwaardeNodeProperties {
    if (isEmpty(prop))
      return {
        Id: null,
        BerichtId: null,
        Description: null,
        Amount: null,
        NegateConditional: null,
        ReferenceId: null,
        MerkMatch: false,
        Optin: false,
        OptinProfiel: null,
      };
    return {
      Id: prop!.Id,
      BerichtId: prop!.BerichtId,
      Description: prop!.Description,
      Amount: prop!.Amount,
      NegateConditional: prop!.NegateConditional,
      AfsluitredenenGemist: prop!.AfsluitredenenGemist,
      AfsluitredenenSuccesvol: prop!.AfsluitredenenSuccesvol,
      SoortenLead: prop!.SoortenLead,
      SoortenAutoGewenst: prop!.SoortenAutoGewenst,
      MerkenAutoGewenst: prop!.MerkenAutoGewenst,
      Vestigingen: prop!.Vestigingen,
      BerichtLinks: prop!.BerichtLinks,
      AfsluitredenenGemistSales: prop!.AfsluitredenenGemistSales,
      SalesSoortenAuto: prop!.SalesSoortenAuto,
      AftersalesVoertuigCategorieen: prop!.AftersalesVoertuigCategorieen,
      MerkenAutoSales: prop!.MerkenAutoSales,
      MerkenAutoAftersales: prop!.MerkenAutoAftersales,
      MerkenAutoOrder: prop!.MerkenAutoOrder,
      OrderSoortenAuto: prop!.OrderSoortenAuto,
      ActiegroepenVerkocht: prop!.ActiegroepenVerkocht,
      ActiegroepenGoedgekeurd: prop!.ActiegroepenGoedgekeurd,
      ActiegroepenBesteld: prop!.ActiegroepenBesteld,
      ActiegroepenAutoBinnen: prop!.ActiegroepenAutoBinnen,
      ActiegroepenAfspraakLevering: prop!.ActiegroepenAfspraakLevering,
      ActiegroepenAfgeleverd: prop!.ActiegroepenAfgeleverd,
      KlantCategorieen: prop!.KlantCategorieen,
      Leadbronnen: prop!.Leadbronnen,
      Kwalificaties: prop!.Kwalificaties,
      LopendWorkflows: prop!.LopendWorkflows,
      ReferenceId: prop!.ReferenceId,
      OpenLeadType: prop!.OpenLeadType,
      MerkMatch: prop!.MerkMatch,
      InitiatiefMatch: prop!.InitiatiefMatch,
      Garanties: prop!.Garanties,
      Optin: prop!.Optin,
      OptinProfiel: prop!.OptinProfiel,
      OrderVoertuigCategorieen: prop!.OrderVoertuigCategorieen,
      OrderVoertuigBrandstoffen: prop!.OrderVoertuigBrandstoffen,
      Media: prop!.Media,
      CompareOperator: prop!.CompareOperator,
      TimeUnit: prop!.TimeUnit,
      TimeValue: prop!.TimeValue,
      DeltaTimeUnit: prop!.DeltaTimeUnit,
      DeltaTimeValue: prop!.DeltaTimeValue,
      RelatieNamen: prop!.RelatieNamen,
      LeadSoortenBrandstoffen: prop!.LeadSoortenBrandstoffen,
      AftersalesSoortenBrandstoffen: prop!.AftersalesSoortenBrandstoffen,
      LeadVoertuigType: prop!.LeadVoertuigType,
      WebsiteTrackers: prop!.WebsiteTrackers,
      Factuursoorten: prop!.Factuursoorten
    };
  }

  private fromVoorwaardeNodeProperties(
    prop: IVoorwaardeNodeProperties | null | undefined
  ): WorkflowDetailStepVoorwaardeViewModel {
    if (isEmpty(prop))
      return {
        Id: null,
        BerichtId: null,
        Description: null,
        Amount: null,
        NegateConditional: null,
        ReferenceId: null,
        MerkMatch: false,
        Optin: false,
        OptinProfiel: null,
      };
    return {
      Id: prop!.Id,
      BerichtId: prop!.BerichtId,
      Description: prop!.Description,
      Amount: prop!.Amount,
      NegateConditional: prop!.NegateConditional,
      AfsluitredenenGemist: prop!.AfsluitredenenGemist,
      AfsluitredenenSuccesvol: prop!.AfsluitredenenSuccesvol,
      SoortenLead: prop!.SoortenLead,
      SoortenAutoGewenst: prop!.SoortenAutoGewenst,
      MerkenAutoGewenst: prop!.MerkenAutoGewenst,
      Vestigingen: prop!.Vestigingen,
      BerichtLinks: prop!.BerichtLinks,
      AfsluitredenenGemistSales: prop!.AfsluitredenenGemistSales,
      SalesSoortenAuto: prop!.SalesSoortenAuto,
      AftersalesVoertuigCategorieen: prop!.AftersalesVoertuigCategorieen,
      MerkenAutoSales: prop!.MerkenAutoSales,
      MerkenAutoAftersales: prop!.MerkenAutoAftersales,
      MerkenAutoOrder: prop!.MerkenAutoOrder,
      OrderSoortenAuto: prop!.OrderSoortenAuto,
      ActiegroepenVerkocht: prop!.ActiegroepenVerkocht,
      ActiegroepenGoedgekeurd: prop!.ActiegroepenGoedgekeurd,
      ActiegroepenBesteld: prop!.ActiegroepenBesteld,
      ActiegroepenAutoBinnen: prop!.ActiegroepenAutoBinnen,
      ActiegroepenAfspraakLevering: prop!.ActiegroepenAfspraakLevering,
      ActiegroepenAfgeleverd: prop!.ActiegroepenAfgeleverd,
      KlantCategorieen: prop!.KlantCategorieen,
      Leadbronnen: prop!.Leadbronnen,
      Kwalificaties: prop!.Kwalificaties,
      LopendWorkflows: prop!.LopendWorkflows,
      ReferenceId: prop!.ReferenceId,
      OpenLeadType: prop!.OpenLeadType,
      MerkMatch: prop!.MerkMatch,
      InitiatiefMatch: prop!.InitiatiefMatch,
      Garanties: prop!.Garanties,
      Optin: prop!.Optin,
      OptinProfiel: prop!.OptinProfiel,
      OrderVoertuigCategorieen: prop!.OrderVoertuigCategorieen,
      OrderVoertuigBrandstoffen: prop!.OrderVoertuigBrandstoffen,
      Media: prop!.Media,
      CompareOperator: prop!.CompareOperator,
      TimeValue: prop!.TimeValue,
      TimeUnit: prop!.TimeUnit,
      DeltaTimeValue: prop!.DeltaTimeValue,
      DeltaTimeUnit: prop!.DeltaTimeUnit,
      RelatieNamen: prop!.RelatieNamen,
      LeadSoortenBrandstoffen: prop!.LeadSoortenBrandstoffen,
      AftersalesSoortenBrandstoffen: prop!.AftersalesSoortenBrandstoffen,
      LeadVoertuigType: prop!.LeadVoertuigType,
      WebsiteTrackers: prop!.WebsiteTrackers,
      Factuursoorten: prop!.Factuursoorten
    };
  }

  private toPrerequisites(
    startstep: WorkflowDetailStepViewModel
  ): IWorkflowNodePrerequisites[] {
    return (get(
      startstep,
      "Prerequisites",
      []
    ) as WorkflowStartPrerequisiteViewModel[]).map((req) => {
      return {
        Id: req.Id,
        IsAbort: req.IsAbort,
        Properties: this.toVoorwaardeNodeProperties(req.Properties),
      };
    });
  }

  private fromPrerequisites(
    node: IWorkflowNode
  ): WorkflowStartPrerequisiteViewModel[] {
    return (get(node, "Prerequisites", []) as IWorkflowNodePrerequisites[]).map(
      (req) => {
        return {
          Id: req.Id,
          IsAbort: req.IsAbort,
          Properties: this.fromVoorwaardeNodeProperties(req.Properties),
        };
      }
    );
  }

  private fromConditions(
    node: IWorkflowNode
  ): WorkflowDetailConditionViewModel[] {
    return (get(node, "Conditions", []) as IWorkflowNodeConditions[]).map(
      (req) => {
        return {
          Id: req.Id,
          Properties: this.fromVoorwaardeNodeProperties(req.Properties),
          Description: req.Description,
        };
      }
    );
  }

  private addChildSteps(
    parentNode: IWorkflowNode,
    steps: WorkflowDetailStepViewModel[]
  ): WorkflowDetailStepViewModel[] {
    //alleen Voorwaarde heeft mogelijke placeholders
    if (
      isEmpty(parentNode.Children) ||
      (parentNode.Children.length === 1 &&
        parentNode.Children[0].StepTypeId === WorkflowNodeType.Placeholder)
    ) {
      return steps;
    }

    parentNode.Children.forEach((node) => {
      steps.push(this.fromNode(node)); //ja komt eerder voor dan nee in de lijst
      steps = this.addChildSteps(node, steps);
    });

    return steps;
  }

  private createChildNodes(
    parentId: string,
    viewModel: WorkflowDetailsDataViewModel
  ): IWorkflowNode[] {
    const childs = this.findChildSteps(parentId, viewModel);
    if (childs.length === 0) {
      const parent = this.findStep(parentId, viewModel);
      const nodetype = this.mapToNodeType(parent!.StepType);
      if (
        nodetype === WorkflowNodeType.Einde ||
        nodetype === WorkflowNodeType.Placeholder
      )
        return [];

      return [
        {
          Id: newId(),
          ParentStepId: parentId,
          StepTypeId: WorkflowNodeType.Placeholder,
          Children: [],
        },
      ];
    }
    if (childs.length === 1) {
      const first = childs[0];

      return [
        {
          Id: first.Id,
          ParentStepId: parentId,
          StepTypeId: this.mapToNodeType(first.StepType),
          Properties: this.toProperties(first),
          Description: first.Description,
          Children: this.createChildNodes(first.Id, viewModel),
          Conditions: first.Conditions,
        },
      ];
    }

    if (childs.length === 2) {
      const ja = childs[0];
      const nee = childs[1];
      return [
        {
          Id: ja.Id,
          ParentStepId: parentId,
          StepTypeId: this.mapToNodeType(ja.StepType),
          Properties: this.toProperties(ja),
          Description: ja.Description,
          Children: this.createChildNodes(ja.Id, viewModel),
          Conditions: ja.Conditions,
        },
        {
          Id: nee.Id,
          ParentStepId: parentId,
          StepTypeId: this.mapToNodeType(nee.StepType),
          Properties: this.toProperties(nee),
          Description: nee.Description,
          Children: this.createChildNodes(nee.Id, viewModel),
          Conditions: nee.Conditions,
        },
      ];
    }
    return [];
  }
}
