








export default {
  name: "unsentmessages-icon",
  props: {
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    color: {
      type: String,

    }
  }
}
