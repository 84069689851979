const observe = (store: any) => {
  let refreshData = {
    PollTimer: null as null | number,
    ShowTimer: null as null | number
  };

  const refreshShow = () => {
    const show = store.getters["Announcements/showAnnouncement"]();
    store.commit("Announcements/setShowRelease", show);
    refreshData.ShowTimer = window.setTimeout(refreshShow, 15 * 1000);
  };

  const refreshPoller = () => {
    store.dispatch("Announcements/refreshReleaseDate").then(() => {
      refreshData.PollTimer = window.setTimeout(refreshPoller, 90 * 1000);
      refreshShow();
    });
  };

  if (store.getters["Identity/hasUserToken"]) refreshPoller();
  else {
    store.watch(
      (state: any, getters: any) => getters["Identity/hasUserToken"],
      (newValue: boolean) => {
        if (newValue && refreshData.PollTimer === null) {
          refreshPoller();
        }
      }
    );
  }
};

export default {
  observe
};
