import Vue from "vue";
import { ConditionEntityType, DropdownItemModel } from "../../TypeScriptModels";
import { Doelgroep, DoelgroepenCount, EntiteitSelectie } from "../../pages/workflows/campagne/CampagneTypes";

// If you make changes and the compiler complains about 'property does not exist on type',
// might want to try restarting the webpack devserver first. Somehow it doesn't reload
// this definition properly and I spent way too long searching for another cause.

//De namen van deze enum niet aanpassen voor backend compatibility:
export enum WorkflowNodeType {
  Placeholder = 1,
  Start = 2,
  Bericht = 3,
  LEF = 4,
  Voorwaarde = 5,
  Wacht = 6,
  Einde = 7,
}

export interface WorkflowEditorViewModel {
  Id: string;
  Titel: string | null;
  Omschrijving: string | null;
  Categorie: DropdownItemModel | null;
  Labels: DropdownItemModel[];
  Laatstgewijzigd: string | null;
  Actief: boolean;
  Data: IWorkflow;
  Versie: number;
  MultipleExecutions: boolean;
  ActieveStoppen: boolean;
  BerichtAccorderen: boolean;
}

export interface IWorkflow {
  Root: IWorkflowNode;
  CampagneData: ICampagneData;
  BerichtAccorderen: boolean;
  HasBerichtStep: boolean;
  doelgroepLookup: IDoelgroepLookup | null;
}

export interface IDoelgroepLookup {
  isPopulated(): boolean;
  populateDoelgroepen(): Promise<void>;
  getDoelgroepen(): Promise<Doelgroep[]>;
  getDoelgroepenSync(): Doelgroep[];
  resolveNames(guids: string[]): Promise<string[]>;
  getLiveCount(guids: string[]): Promise<DoelgroepenCount>;
  getRefinedLiveCount(guids: string[]): Promise<DoelgroepenCount>;
  setSavedCountAvailable(): void;
  getSavedCount(): Promise<number>;
  getConditionEntityIntersection(guids: string[]): ConditionEntityType[]; 
  validateActive(guids: string[]): boolean;
  validateAllContainVoertuig(guids: string[]): boolean;
}

export interface ICampagneData {
  AantalPerDag: number | null;
  StartMoment: Date | null;
  StartTijd: Date | null;
  Doelgroepen: string[] | null;
  StartEntiteit: string | null;
}

export interface IWorkflowNode {
  Id: string;
  ParentStepId?: null | string;
  StepTypeId: WorkflowNodeType;
  Properties?: IBaseNodeProperties;
  Description?: null | string;
  Children: IWorkflowNode[];
  Prerequisites?: IWorkflowNodePrerequisites[];
  Conditions?: IWorkflowNodeConditions[];
  Badge?: number;
}

export interface IWorkflowNodeDictionary {
  [Id: string]: IWorkflowNode;
}

export interface IWorkflowNodePrerequisites {
  Id: string;
  IsAbort: boolean;
  Properties?: IVoorwaardeNodeProperties | null;
}

export interface IWorkflowNodeConditions {
  Id: string;
  Properties?: IVoorwaardeNodeProperties | null;
  Description?: null | string;
}

export interface IVoorwaardeNodePropertiesChanged {
  StepId: string;
  CombiStepId: string;
  isPrerequisites: boolean;
  IsPrerequisitesAbort?: boolean;
  PropertyFieldChanges: {
    key: string;
    val:
      | null
      | boolean
      | number
      | number[]
      | string
      | string[]
      | BerichtLinks[];
  }[];
}

export interface IBaseNodeProperties {}

export interface IStartNodeProperties extends IBaseNodeProperties {
  Id: number | null;
  IsTimedStart: boolean;
  Garantie: string | null;
  TimeValue?: number | null;
  TimeUnit?: number | null;
  StartMoment?: string | null;
  StartTijd?: string | null;
  AantalPerDag?: number | null;
  Doelgroepen?: string[] | null;
  StartEntiteit?: EntiteitSelectie | null;
}

export interface IWachtNodeProperties extends IBaseNodeProperties {
  TimeValue: number | null;
  TimeUnit: number | null;
}

export interface IEindeNodeProperties extends IBaseNodeProperties {
  Id: number | null;
}

export interface IBerichtNodeProperties extends IBaseNodeProperties {
  BerichtId: string | null;
  BerichtTypeOrder: string[];
}

export interface ILEFNodeProperties extends IBaseNodeProperties {
  Id: number | null;
  LeadType: string | null;
  SoortLead: string | null;
  LeadInitiatief: string | null;
}

export interface IVoorwaardeNodeProperties extends IBaseNodeProperties {
  Id: number | null;
  BerichtId: string | null;
  Description: string | null;
  Amount: number | null;
  NegateConditional: boolean | null;
  AfsluitredenenGemist?: number[] | null;
  AfsluitredenenSuccesvol?: number[] | null;
  SoortenLead?: string[] | null;
  SoortenAutoGewenst?: number[] | null;
  MerkenAutoGewenst?: number[] | null;
  Vestigingen?: string[] | null;
  BerichtLinks?: BerichtLinks[] | null;
  AfsluitredenenGemistSales?: string[] | null;
  SalesSoortenAuto?: string[] | null;
  AftersalesVoertuigCategorieen?: string[] | null;
  MerkenAutoSales?: number[] | null;
  MerkenAutoAftersales?: number[] | null;
  MerkenAutoOrder?: number[] | null;
  OrderSoortenAuto?: string[] | null;
  ActiegroepenVerkocht?: string[] | null;
  ActiegroepenGoedgekeurd?: string[] | null;
  ActiegroepenBesteld?: string[] | null;
  ActiegroepenAutoBinnen?: string[] | null;
  ActiegroepenAfspraakLevering?: string[] | null;
  ActiegroepenAfgeleverd?: string[] | null;
  KlantCategorieen?: string[] | null;
  Leadbronnen?: number[] | null;
  Kwalificaties?: number[] | null;
  LopendWorkflows?: string[] | null;
  ReferenceId?: number | null;
  OpenLeadType?: number | null;
  MerkMatch: boolean;
  InitiatiefMatch?: number[] | null;
  Garanties?: string[] | null;
  Optin: boolean;
  OptinProfiel: number | null;
  OrderVoertuigCategorieen?: string[] | null;
  OrderVoertuigBrandstoffen?: string[] | null;
  Media?: number[] | null;
  CompareOperator?: number | null;
  TimeValue?: number | null;
  TimeUnit?: number | null;
  DeltaTimeValue?: number | null;
  DeltaTimeUnit?: number | null;
  RelatieNamen?: string[] | null;
  LeadSoortenBrandstoffen?: string[] | null;
  AftersalesSoortenBrandstoffen?: string[] | null;
  LeadVoertuigType?: string | null;
  WebsiteTrackers?: string[] | null;
  Factuursoorten?: string[] | null;
}

export interface ICombinatieVoorwaardeNodeProperties
  extends IBaseNodeProperties {
  Id: number | null;
  Description: string | null;
  Operator: number | null;
}

export interface IBaseNodePropertiesChanged {
  Id: string;
  FieldChanges: {
    key: string;
    val: null | boolean | number | number[] | string | string[];
  }[];
}

export interface BerichtLinks {
  Url: string;
  Description: string;
}

export interface IWorkflowViewOptions {
  nodeWidth: number;
  nodeHeight: number;

  infoWidth: number;
  infoPadding: number;
  badgePadding: number;
  readonly: boolean;
  clickable: boolean;
  connectorHeight: number;
  showAnimation: boolean;
}

export interface IBounds {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

export interface IPosition {
  x: number;
  y: number;
}

// TypeScript doesn't understand Vue 'extends', so this interface
// provides typed access to the inherited properties of nodes
export interface IAbstractNodeProps {
  viewBus: Object;
  node: IWorkflowNode;
  selectedNode: IWorkflowNode;
  options: IWorkflowViewOptions;
  position: IPosition;

  nodeX: number;
  nodeY: number;

  getProperties(): IBaseNodeProperties;
  getDescription(): string | null | undefined;
  getNodeHeight(): number;
  getNodeWidth(): number;

  emitBounds(bounds: IBounds): void;
  emitSelect(node: IWorkflowNode): void;
  emitInsert(node: IWorkflowNode): void;
}

export const ICON_FILTER = "_~FILTER~_";
export const ICON_WAIT = "_~WAIT~_";

export enum WachtEenheid {
  Onbekend = 0,
  Minute = 1,
  Hour = 2,
  Day = 3,
  Week = 4,
  Month = 5,
}

export enum Categorie {
  Lead = 1,
  Sales = 2,
  Aflevering = 3,
  Aftersales = 4,
  Campagne = 5,
}

export enum Leadtype {
  Sales = 1,
  Aftersales = 2,
  Fleetsales = 3,
}

export enum OpenLeadType {
  Sales = 1,
  Aftersales = 2,
  Beide = 3,
  Origineel = 4,
}

export enum NegateConditionalOptions {
  DontNegate = 0,
  Negate = 1,
}

export enum CompareOperatorConditionalOptions {
  Minder = 1,
  Meer = 2,
  Gelijk = 3,
}

export enum MerkMatch {
  Same = 0,
  Independent = 1,
}

export enum MatchLeadInitiatiefType {
  Onbekend = 0,
  Klant = 1,
  Autobedrijf = 2,
  Importeur = 3,
}

export enum AbortOption {
  Abort = 0,
  NoAbort = 1,
}

export enum ConditionOperator {
  And = 0,
  Or = 1,
}

export enum StartTriggerType {
  NieuwLead = 1,
  NieuwLeadSales = 2,
  NieuwLeadAftersales = 3,
  GemistLead = 4,
  GemistLeadSales = 5,
  GemistLeadAftersales = 6,

  NieuwSalesTraject = 21,
  GemistSalesTraject = 22,
  SalesOrder = 23,

  Goedgekeurd = 43,
  AutoBesteld = 45,
  KentekenBekend = 47,
  AutoBinnen = 49,
  AfspraakLevering = 51,
  AutoAfgeleverd = 53,
  KlantBetaald = 55,
  DatumUitFabriekBekend = 56,
  ProductiedatumBekend = 57,

  VolgendApk = 60,
  VolgendOnderhoud = 61,
  Tenaamstelling = 65,
  EersteAfgifte = 66,
  EersteToelating = 67,
  Werkplaatsafspraak = 70,
  Werkplaatsfactuur = 71,

  EindeGarantie = 80,

  Campagne = 90,
}

export enum VoorwaardeType {
  AfsluitredenenGemist = 2,
  SoortLead = 3,
  GewenstSoort = 4,
  GewenstMerk = 5,//Heet nu LeadMerkAuto in backend en is voor huidig alswel gewenst voertuig
  LeadVestiging = 6,
  AfsluitredenenSuccesvol = 7,
  LeadAangemaaktBinnenOpeningstijden = 8,
  Leadbron = 10,
  Leadkwalificatie = 11,
  LeadRelatieNaam = 12,
  LeadSoortBrandstof = 13,
  AfsluitredenenGemistSales = 21,
  SalesMerk = 23,
  SalesSoort = 24,
  SalesVestiging = 25,
  SalesRelatieNaam = 26,
  OrderMerk = 40,
  OrderSoortAuto = 41,
  OrderVestiging = 42,
  OrderKlantCategorie = 43,
  OrderVoertuigCategorie = 44,
  OrderVoertuigBrandstof = 45,

  EmailOpened = 61,
  MAXLinkClicked = 62,
  MaxLopendWorkflow = 63,

  HeeftTelefoon = 81,
  HeeftEmail = 82,
  HeeftLeaseRelatie = 84,
  HeeftZakelijkeRelatie = 85,
  HeeftNatuurlijkPersoon = 86,
  HeeftOpenLead = 87,
  HeeftOpenSalesOfAflevering = 88,
  KanBenaderen = 89,
  HeeftVoertuigen = 90,

  ActiegroepVerkocht = 91,
  ActiegroepGoedgekeurd = 92,
  ActiegroepBesteld = 93,
  ActiegroepAutoBinnen = 94,
  ActiegroepAfspraakLevering = 95,
  ActiegroepAfgeleverd = 96,
  AfleveringRelatieNaam = 97,

  DatumUitFabriek = 101,
  Productiedatum = 102,

  AftersalesMerk = 111,
  AftersalesVoertuigCategorie = 112,
  AftersalesVestiging = 113,
  AftersalesAPKDatum = 114,
  AftersalesOnderhoudDatum = 115,
  AftersalesLaatsteTenaamstelling = 117,
  AftersalesRelatieNaam = 118,
  AftersalesSoortBrandstof = 119,
  AftersalesEersteToelating = 121,
  AftersalesGaranties = 122,
  WerkplaatsfactuurBovenBedrag = 130,
  WerkplaatsfactuurOnderhoud = 131,
  WerkplaatsfactuurFactuursoorten = 132,
  WerkplaatsfactuurDatum = 133,
  VoertuigGarantie = 140,

  ActieveKlant = 150,
  HeeftOpenVerkooptraject = 151,
  HeeftOpenAflevering = 152,
  HeeftRecenteLead = 160,
  HeeftRecentVerkooptraject = 161,
  HeeftWebsiteBezocht = 162,
}
