export default {
  yes: "Ja",
  no: "Nein",
  okayCapital: "OK",
  yessmall: "ja",
  nosmall: "nein",
  switchLanguage: "Sprache ändern",
  menuoverzicht: "Meine Übersicht",
  beheer: "Verwaltung",
  searchPlaceholder: "Suchen ...",
  new: "Neu",
  newWorkflow: "Neuer durchlaufender Arbeitsablauf",
  newCampaign: "Neuer Kampagnen-Arbeitsablauf",
  details: "Details",
  navigationBack: "Zurück",
  select: "Auswählen",
  apply: "Anwenden",
  sortMostRecent: "Datum geändert",
  sortTitle: "Titel",
  sortStartDate: "Startdatum",
  sortName: "Name",
  save: "Speichern",
  cancel: "Abbrechen",
  active: "Aktiv",
  today: "Heute",
  now: "Jetzt",
  minutes: "Minuten",
  hours: "Stunden",
  days: "Tage",
  weeks: "Wochen",
  months: "Monate",
  minute: "Minute",
  hour: "Stunde",
  day: "Tag",
  week: "Woche",
  month: "Monat",
  close: "Schließen",
  undo: "rückgängig machen",
  insert: "Einfügen",
  collapse: "Einklappen",
  expand: "Ausklappen",
  send: "Absenden",
  overwrite: "Überschreiben",
  logout: "Abmelden",
  application: "Anwendung",
  settings: "Einstellungen",
  customerService: "Kundendienst",
  rapportage: "Bericht",
  dashboard: "Dashboard",
  doelgroepen: "Zielgruppen",
  lef: "LEF",
  stepBericht: "Nachricht",
  stepVoorwaarde: "Bedingung",
  stepWacht: "Warten",
  stepEinde: "Ende",
  actieveKlant: "Aktiver Kunde",
  werkplaatsplanner: "Werkstattplaner",
  webtracking: "Webtracking",
  voertuigbronnen: "Fahrzeugquellen",
  showInactive: "Inaktive anzeigen",
  copyCampagne: "Kampagne kopieren",
  omschrijving: "Beschreibung",
  voorbeeld: "Beispiel",
  timespan: "Zeitraum",
  vanaf: "Von",
  inclusiveTill: "Bis einschließlich",
  vestiging: "Standort",
  vestigingen: "Standorte",
  vestigingenAll: "Alle Standorte",
  vestigingenSelect: "Standort(e) auswählen",
  voertuigFilter: "Fahrzeugtyp",
  voertuigFilterAll: "Alle Arten",
  voertuigFilterSelect: "Typ(en) auswählen",
  exporteren: "Exportieren",
  soortAuto: {
    Nieuw: "Neu",
    Occasion: "Gebrauchtwagen",
    Voorraad: "Lagerfahrzeug"
  },
  widget: {
    leadsalesTitle: "Verkaufs{linebreak}Leads",
    leadaftersalesTitle: "Nachverkauf{linebreak}Leads",
    salesTitle: "Verkauf",
    aflevering: "Auslieferungen",
    aftersales: "Nachverkauf",
    totals: "Summe: {count}",
    total: "Summe",
    widgetstart: "begonnen am: {datum}",
    widgetended: "abgeschlossen am: {datum}",
    retrieving: "Daten werden gesammelt ...",
    unknown: "Unbekannt",
    ongoing: "Laufend",
    drilldownWorkflowLast: "Arbeitsabläufe vor letzter Änderung",
    drilldownWorkflowLastChange: "Dieser Arbeitsablauf wurde zuletzt geändert am {lastChangedDateFormatted}. Arbeitsabläufe, die vor der letzten Änderung gestartet wurden, sind in der untenstehenden Tabelle wiedergegeben.",
    errorConnectionReport: "Fehler beim Abrufen des Berichts. Versuchen Sie es bitte später erneut oder wenden Sie sich an unseren Kundendienst.",
    errorOldBrowser:  "Ihr Browser wird nicht unterstützt, der Bericht kann nicht abgerufen werden.",
    errorConnection: "Der Aufbau einer Verbindung für Echtzeitaktualisierungen ist fehlgeschlagen.",
    errorConnectionRetry: "Aktualisieren Sie diese Seite, um die aktuellen Daten abzurufen.",
    noData: "Keine Daten verfügbar",
    unknownWorkflow: "Unbekannter Arbeitsablauf",
    headersFullsize:{
      workflow: "Arbeitsablauf",
      totals: "Summe",
      success: "Erfolgreich",
      failures: "Kein Erfolg.",
      ongoing: "Laufend",
      converted: "Konversion",
      notApplicable: "Nicht zutr."
    },
    headersTinysize:{
      workflow: "Arbeitsablauf",
      totals: "Summe",
      success: "Erfolg",
      failures: "Kein Erfolg",
      ongoing: "Laufend",
      converted: "Konv.",
      notApplicable: "Nicht zutr."
    }
  }, 
  workflows: {
    soortLead: "Lead-Art",
    lefStepCopy: "Übernehmen aus vorigem Lead",
    title: "Arbeitsabläufe",
    titleCampagne: "Arbeitsabläufe",
    filteredCount: "{tabtitle} ({count} von {total})",
    searchPlaceholder: "Arbeitsablauf suchen ...",
    when: "Wann",
    starttrigger: "Bei Datumsänderung",
    aantalStartvoorwaardenMore: "Startbedingung | Startbedingungen",
    aantalStartvoorwaarden: "{count} Startbedingung | {count} Startbedingungen",
    entiteitTextRelaties : "{count} Beziehung | {count} Beziehungen",
    entiteitTextVoertuigen : "{count} Fahrzeug | {count} Fahrzeuge",
    entiteitTextEntiteiten : "{count} Entität | {count} Entitäten",
    category: "Kategorie",
    labels: "Etiketten",
    copy: "Kopieren",
    medium: "Mittel",
    chooseMedium: "Wählen Sie einen Mittel",
    categoryChoice: {
      all: "Alle Arbeitsabläufe",
      lead: "Lead-Arbeitsabläufe",
      sales: "Verkaufs-Arbeitsabläufe",
      aftersales: "Nachverkauf-Arbeitsabläufe",
      delivery: "Auslieferung-Arbeitsabläufe"
    },
    campagnePeriod: {
      custom: "Freier Zeitraum",
      fromNow: "Zukunft",
      lastMonths: "Innerhalb von 3 Monaten",
      year: "Dieses Jahr",
      lastYear: "Letztes Jahr"
    },
    periods: {
      custom: "Freier Zeitraum",
      today: "Heute",
      yesterday: "Gestern",
      thisweek: "Diese Woche",
      lastweek: "Letzte Woche",
      thismonth: "Dieser Monat",
      lastmonth: "Letzter Monat",
      thisquarter:"Dieses Quartal",
      lastquarter:"Letztes Quartal",
      year: "Dieses Jahr",
      lastYear: "Letztes Jahr"
    }
  },

  workflow: {
    actionCenter: "Arbeitsablauf zentrieren", 
    toCenter: "Zentrieren", 
    resetZoomFactor: "Doppelklick für 100 %",
    startMore: "Alles anzeigen",
    startLess: "Weniger anzeigen",
    onbekend: "Unbekannt",
    berichtaccorderen: "Nachrichten vorab genehmigen",
    title: "Arbeitsablauf",
    doelgroep: "Zielgruppe",
    doelgroepTitle: "{count} Zielgruppe | {count} Zielgruppen",
    tabWorkflows: "Durchlaufende Arbeitsabläufe",
    tabCampagnes: "Kampagnen-Arbeitsabläufe",
    aantalRelaties: "{count} Beziehung | {count} Beziehungen",
    campagneStartDay: "begonnen am {day}",
    campagneFinalDay: "abgeschlossen am {day}",
    subject: "Betreff",
    copied: "Kopie von {title}",
    campagneDoelgroepen: "Zielgruppen",
    campagneSearchDoelgroepen: "Zielgruppe suchen ...",
    searchNoResults: "Keine Ergebnisse",
    paneltitle: "Arbeitsablaufdetails",
    insertTitle: "Geben Sie einen Titel ein ...",
    insertSubject: "Geben Sie eine Beschreibung ein ...",
    saveRow: "Als neue Zeile speichern",
    selectLabel: "Etikett(en) auswählen",
    panelDetailTitle: "Allgemeine Information",
    starttitel: "Startzeitpunkt",
    startmoment: "Start",
    startprerequisites: "Startbedingungen",
    addstartprerequisite: "Hinzufügen",
    addedstartprerequisite: "Hinzugefügt",
    selectstart: "Startmoment auswählen",
    selectwacht: "Geben Sie einen Zeitraum ein",
    selectkwalificatie: "Eine Qualifizierung auswählen",
    selectOptin: "Profil auswählen",
    selectCompareMake: "Vergleichsmarke auswählen",
    selectFromDropdown: "Option auswählen",
    contains: "Enthält",
    containsnot: "Enthält nicht",
    itContains: "{item} enthält",
    itContainsnot: "{item} enthält nicht",
    selectleadtype: "Lead-Typ auswählen",
    selectleadinitiatief: "Lead-Initiative auswählen",
    selectsoortlead: "Lead-Art(en) auswählen",
    selectsoortleadsingle: "Lead-Art auswählen",
    selectleadsource: "Lead-Quelle auswählen",
    selectleadcartype: "Typ Wunschfahrzeug auswählen",
    selectleadcarmake: "Gewünschte Marke(n) auswählen",
    selectleadcartypespecified: "Fahrzeugtyp auswählen",
    selectaftersalescarmake: "Marke(n) auswählen",
    selectaftersalescartype: "Fahrzeugkategorie auswählen",
    selectleadbranch: "Standort(e) auswählen",
    selectsalesbranch: "Standort(e) auswählen",
    selectorderbranch: "Standort(e) auswählen",
    selectGarantie: "Vertragstyp auswählen",
    selectFactuursoort: "Rechnungstyp auswählen",
    garantieSoort: "Vertragstyp",
    selectendtitle: "Ende",
    selectsteptitle: "Schritte",
    selectActiegroep: "Aktionsgruppe(n) auswählen",
    stepbericht: "Nachricht",
    stepLEF: "LEF",
    StepLefTitle: "LEF",
    stepLEFKwalificatie: "Qualifizierung",
    stepLEFDescription: "Beschreibung",
    stepLEFInsertTitle: "Geben Sie eine Beschreibung ein ...",
    stepLEFleadType: "Lead-Typ",
    stepLEFLeadInitiatief: "Initiative",
    stepVoorwaarde: "Bedingung",
    stepVoorwaardeTitle: "Bedingung",
    stepVoorwaardeDescription: "Beschreibung",
    selectVoorwaarde: "Bedingung auswählen",
    selectVoorwaardeAfsluitredenSucces: "Beendigungsgrund auswählen",
    selectVoorwaardeAfsluitreden: "Beendigungsgrund auswählen",
    selectVoorwaardeAfsluitredenSales: "Beendigungsgrund auswählen",
    selectVoorwaardeLopendeWorkflows: "Gestarteten Arbeitsablauf auswählen",
    selectVoorwaardeOpenLeadType: "Lead-Typ auswählen",
    selectVoorwaardeHeeftVoertuig: "Vergleich auswählen",
    selectVoorwaardeBrandSame: "Für dieselbe Marke",
    selectVoorwaardeBrandIndependent: "Dieselbe oder andere Marken",
    merk: "Marke",
    profiel: "Profil",
    leadtype: "Lead-Typ",
    leadinitiatief: "Initiative",
    hasOpenSalesLead: "Hat offenstehenden Verkaufslead",
    hasOpenAftersalesLead: "Hat offenstehenden Nachverkauflead",
    hasNoOpenLead: "Hat keinen offenstehenden Lead",
    hasNoOpenSalesLead: "Hat keinen offenstehenden Verkaufslead",
    hasNoOpenAftersalesLead: "Hat keinen offenstehenden Nachverkauflead",
    hasNoOpenSalesOrAflevering: "Hat keinen laufenden Verkaufsprozess/Auslieferung",
    hasNoOpenSales: "Hat keinen laufenden Verkaufsprozess",
    hasNoOpenAflevering: "Hat keine laufende Auslieferung",
    hasTelefoon: "Hat Telefonnummer",
    hasNoTelefoon: "Hat keine Telefonnummer",
    hasEmail: "Hat E-Mail-Adresse",
    hasNoEmail: "Hat keine E-Mail-Adresse",
    kanBenaderen: "Hat E-Mail-Adresse/Telefonnummer",
    kanNietBenaderen: "Hat keine E-Mail-Adresse/Telefonnummer",
    abort: "Arbeitsablauf abbrechen",
    noAbort: "Arbeitsablauf weiterlaufen lassen",
    tileAbortDescription: "Kann Arbeitsablauf abbrechen",
    expiratie: "Wartezeit bis „Nein“",
    preconditionDuringWorkflow: "Wenn die Bedingung während des Arbeitsablaufs nicht mehr erfüllt ist",
    expiratieNegate: "Wartezeit bis „Ja“",
    selectlinkcklick: "Link(s) auswählen",
    stepEinde: "Ende",
    stepEindeResult: "Ergebnis",
    stepEindeResultOk: "Erfolgreich",
    stepEindeResultNok: "Nicht erfolgreich",
    stepEindeResultNA: "Nicht zutreffend",
    stepTimer: "Für",
    stepTimerTitle: "Warten",
    selectBericht: "Nachricht auswählen",
    selectSite: "Website auswählen",
    changeBericht: "Eine andere Nachricht auswählen",
    noBericht: "Keine Nachricht ausgewählt",
    selectResultaat: "Ergebnis auswählen",
    stepsRemoved: "gelöscht",
    saved: "Arbeitsablauf ist gespeichert",
    enterAmount: "Geben Sie einen Betrag ein",
    selectKlantCategorie: "Kundenkategorie auswählen",
    orderVoertuigCategorie: "Fahrzeugkategorie auswählen",
    orderVoertuigBrandstof: "Kraftstoff auswählen",
    aftersalesVoertuigBrandstof: "Kraftstoff auswählen",
    keepWhichPath: "Arbeitsablauf fortsetzen mit ",
    optin: "Opt\u2011in: ",
    checkOptin: "Opt\u2011in kontrollieren",
    noOptin: "Keine Opt\u2011in-Kontrolle",
    recenteLeadDelta: "In den vergangenen",
    withinRecentTimespan: "in den vergangenen {timespan}",
    numberOfVisits: "Mindestanzahl der Seitenaufrufe seit Startzeitpunkt",
    hasRecentSalesLead: "Hat kürzlich erstellten Verkaufslead",
    hasRecentAftersalesLead: "Hat kürzlich erstellten Nachverkauflead",
    hasNoRecentLead: "Hat keinen kürzlich erstellten Lead",
    hasNoRecentSalesLead: "Hat keinen aktuellen Verkaufslead",
    hasNoRecentAftersalesLead: "Hat keinen aktuellen Nachverkauflead",
    hasNoRecentSales: "Hat keinen kürzlich erstellten Verkaufsprozess",
    workflowCategories: {
      Id1: "Lead",
      Id2: "Verkauf",
      Id3: "Auslieferung",
      Id4: "Nachverkauf",
      Id5: "Kampagne"
    },
    startTriggers: {
      Id1: "Neuer Lead",
      Id2: "Neuer Verkaufslead",
      Id3: "Neuer Nachverkauflead",
      Id4: "Verpasster Lead",
      Id5: "Verpasster Verkaufslead",
      Id6: "Verpasster Nachverkauflead",
      Id7: "Erfolgreicher Lead",
      Id8: "Erfolgreicher Verkaufslead",
      Id9: "Erfolgreicher Nachverkauflead",
      Id21: "Neuer Verkaufsprozess",
      Id22: "Verpasster Verkaufsprozess",
      Id23: "Verkauft",
      Id43: "Genehmigt",
      Id45: "Auto bestellt",
      Id47: "Kennzeichen bekannt",
      Id49: "Auto eingetroffen",
      Id51: "Termin für Lieferung",
      Id53: "Auto ausgeliefert",
      Id55: "Kunde bezahlt",
      Id56: "Datum ab Werk bekannt",
      Id57: "Produktionsdatum bekannt",
      Id60: "TÜV",
      Id61: "Nächste Inspektion",
      Id70: "Werkstatttermin",
      Id71: "Werkstattrechnung",
      Id80: "Ablauf Verträge",
      Id65: "Letzte Anmeldung",
      Id66: "Erstregistrierung",
      Id67: "Erstzulassung",
      Id90: "Kampagne"
    },
    voorwaardeGroepen: {
      Id1: "Lead",
      Id2: "Verkauf",
      Id3: "Auftrag",
      Id4: "Auslieferung",
      Id5: "MAX",
      Id6: "Aktionsgruppe",
      Id7: "Beziehung",
      Id8: "Nachverkauf",
      Id9: "Werkstatttermin",
      Id10: "Werkstattbesuch"
    },
    voorwaarden: {
      Id1: "Lead erfolgreich",
      Id2: "Beendigungsgrund verpasst",
      Id3: "Lead-Art",
      Id4: "Fahrzeugtyp (gewünscht)",
      Id5: "Marke",
      Id6: "Lead Standort",
      Id7: "Beendigungsgrund erfolgreich",
      Id8: "Lead innerhalb von Nachverfolgungszeiten",
      Id10: "Lead-Quelle",
      Id11: "Qualifizierung",
      Id12: "Name gekoppelte Beziehung",
      Id13: "Kraftstoffart",
      Id170: "Beziehung ist Leasinggesellschaft",
      Id175: "Beziehung ist geschäftlich",
      Id180: "Beziehung ist natürlich",
      Id20: "Auftrag",
      Id21: "Beendigungsgrund verpasst",
      Id22: "Angebot erstellt",
      Id23: "Marke",
      Id24: "Fahrzeugtyp",
      Id25: "Standort",
      Id26: "Name gekoppelte Beziehung(en)",
      Id171: "Enthält eine Leasinggesellschaft",
      Id176: "Enthält eine geschäftliche Beziehung",
      Id181: "Enthält eine natürliche Beziehung",
      Id40: "Marke",
      Id41: "Fahrzeugtyp",
      Id42: "Standort",
      Id43: "Kundenkategorie",
      Id44: "Fahrzeugkategorie",
      Id45: "Kraftstoffart",
      Id61: "E-Mail geöffnet",
      Id62: "Link angeklickt",
      Id63: "Kürzlich gestarteter Arbeitsablauf",
      Id91: "Verkauft",
      Id92: "Genehmigt",
      Id93: "Bestellt",
      Id94: "Auto eingetroffen",
      Id95: "Termin für Lieferung",
      Id96: "Ausgeliefert",
      Id97: "Name gekoppelte Beziehung(en)",
      Id172: "Enthält eine Leasinggesellschaft",
      Id177: "Enthält eine geschäftliche Beziehung",
      Id182: "Enthält eine natürliche Beziehung",
      Id100: "Kunde bezahlt",
      Id101: "Datum ab Werk",
      Id102: "Produktionsdatum",
      Id89: "Hat E-Mail-Adresse/Telefonnummer",
      Id81: "Hat Telefonnummer",
      Id82: "Hat E-Mail-Adresse",
      Id87: "Hat offenstehenden Lead",
      Id90: "Anzahl Fahrzeuge",
      Id151: "Hat laufenden Verkaufsprozess",
      Id152: "Hat laufende Auslieferung",
      Id150: "Ist ein aktiver Kunde",
      Id160: "Hat kürzlich erstellten Lead",
      Id161: "Hat kürzlich erstellten Verkaufsprozess",
      Id162: "Hat Website besucht",
      Id110: "Hat Werkstatttermin",
      Id111: "Marke",
      Id112: "Fahrzeugkategorie",
      Id113: "Standort",
      Id114: "TÜV",
      Id115: "Nächste Inspektion",
      Id140: "Erweiterte Verträge",
      Id116: "Erster Eigentümer",
      Id117: "Letzte Anmeldung",
      Id118: "Name gekoppelte Beziehung(en)",
      Id119: "Kraftstoffart",
      Id173: "Enthält eine Leasinggesellschaft",
      Id178: "Enthält eine geschäftliche Beziehung",
      Id183: "Enthält eine natürliche Beziehung",
      Id121: "Erstzulassung",
      Id122: "Laufende Verträgen",
      Id120: "Ersatztransport",
      Id123: "Beziehung wartet",
      Id131: "Rechnung für Inspektion",
      Id130: "Rechnungsbetrag Werkstattbesuch",
      Id132: "Art der Rechnung"
    },
    leadInitiatiefType: {
      onbekend: "Unbekannt",
      klant: "Kunde",
      autobedrijf: "Autohaus",
      importeur: "Importeur"
    },
    leadInitiatieven: {
      Klant: "Kunde",
      Autobedrijf: "Autohaus",
      Importeur: "Importeur"
    },
    leadSoortenBrandstoffen: {
      Benzine: "Benzin",
      Diesel: "Diesel",
      LPG: "LPG",
      Elektrisch: "Elektrisch",
      Hybride: "Hybrid",
      HybrideDiesel: "Diesel-Hybrid",
      HybrideLPG: "LPG-Hybrid",
      CNG: "CNG",
      Waterstof: "Wasserstoff",
      Overige: "Sonstige"
    },
    orderVoertuigBrandstoffen: {
      Benzine: "Benzin",
      LPG: "LPG",
      Diesel: "Diesel",
      Elektrisch: "Elektrisch",
      HEV: "HEV",
      PHEV: "PHEV",
      Overig: "Sonstige"
    },
    aftersalesSoortenBrandstoffen: {
      Benzine: "Benzin",
      Diesel: "Diesel",
      Elektrisch: "Elektrisch",
      LPG: "LPG",
      Aardgas: "Erdgas",
      LPGBenzine: "LPG/Benzin",
      AardgasBenzine: "Erdgas/Benzin",
      HybrideBenzine: "Benzin-Hybrid",
      HybrideDiesel: "Diesel-Hybrid",
      HybrideLPG: "LPG-Hybrid",
      Waterstof: "Wasserstoff",
      HEV: "HEV",
      PHEV: "PHEV",
      Overig: "Sonstige"
    },
    soortenLead: {
      Accessoires: "Zubehör",
      APK: "TÜV",
      Banden: "Reifen",
      Bellijst: "Telefonliste",
      Bieding: "Übernahmeangebot",
      Brochure: "Broschüre",
      CarConfigurator: "Fahrzeugkonfigurator",
      Contactverzoek: "Kontaktanfrage",
      Event: "Ereignis",
      Financiering: "Finanzierung",
      Garantie: "Verträge",
      HoudMijOpDeHoogte: "Halten Sie mich auf dem Laufenden",
      Klacht: "Reklamation",
      Klanttevredenheid: "Kundenzufriedenheit",
      Laadpaal: "Ladesäule",
      Loyalty: "Treueprogramm",
      Mailing: "Mailing",
      Offerte: "Angebot",
      Onderhoud: "Inspektion",
      Overig: "Sonstige",
      PrivateLease: "Privatleasing",
      Proefrit: "Probefahrt",
      Reparatie: "Reparatur",
      Schade: "Schaden",
      ShortLease: "Kurzzeitleasing",
      Showroombezoek: "Ausstellungsraumbesuch",
      Taxatie: "Schätzung",
      TelefonischeLead: "Telefonischer Lead",
      Terugbelverzoek: "Rückrufbitte",
      Terugroepactie: "Rückrufaktion",
      Verhuur: "Vermietung",
      Verzekering: "Versicherung",
      Webshop: "Webshop",
      Werkplaatsafspraak: "Werkstatttermin",
      Werkplaatsofferte: "Werkstattangebot",
      ZakelijkLeasen: "Gewerbeleasing",
      Zoekopdracht: "Suchauftrag"
    },
    leadTypenVoertuig: {
      OpBasisVanLeadType: "Auf Basis von Lead-Typ",
      GewenstVoertuig: "Wunschfahrzeug",
      HuidigVoertuig: "Aktuelles Fahrzeug"
    },
    mediaTypen: {
      Id1: "E-Mail",
      Id2: "Telefon",
      Id3: "Messaging"
    },
    leadTypen: {
      Sales: "Verkauf",
      Aftersales: "NachVerkauf",
      Fleetsales: "Flottenverkauf",
      Origineel: "Übernehmen aus vorigem Lead"
    },
    openLeadTypes: {
      Id1: "Verkauf",
      Id2: "NachVerkauf",
      Id3: "Verkauf oder NachVerkauf",
      Id4: "Lead-Typ des Startzeitpunkts"
    },
    soortenAuto: {
      Nieuw: "Neu",
      Occasion: "Gebrauchtwagen",
      VoorraadAuto: "Lagerfahrzeug"
    },
    optinProfielen: {
      Id1: "Autoinformation",
      Id2: "Marken- und Modellinformation",
      Id3: "Unternehmensinformation",
      Id4: "Sonstiges/Kommerziell",
      Id5: "Umfragen"
    },
    aftersalesVoertuigCategorieen: {
      Personenauto: "Personenkraftwagen",
      Bedrijfswagen: "Firmenwagen"
    },
    orderVoertuigCategorieen: {
      Geel: "Personenkraftwagen",
      Grijs: "Firmenwagen"
    },
    descriptionSiteVisits: "{count} Mal für {item} |{count} Mal für {item}",
    selectInitiatiefType: "Initiative auswählen",
    subconditions: "Unterbedingungen",
    meetsSubconditions: "Erfüllt",
    allSubconditions: "Alle Unterbedingungen",
    anySubconditions: "Eine der Unterbedingungen",
    addSubcondition: "Hinzufügen",
    subconditionCriteria: "Kriterien auswählen",
    tileSubconditionsAllDescription: "Erfüllt alle<br>{count} Unterbedingungen",
    tileSubconditionsAnyDescription: "Erfüllt eine der<br>{count} Unterbedingungen",
    tileSubconditionsCountDescription: "Enthält {count} Unterbedingung | Enthält {count} Unterbedingungen",
    selectRelatienaam: "Geben Sie einen Namen ein",
    category: {
      all: "Alle Arbeitsabläufe",
      lead: "Lead",
      sales: "Verkauf",
      aftersales: "Nachverkauf",
      delivery: "Auslieferung",
      campaign: "Kampagne"
    },
    dataEntiteit: {
      relatie: "Beziehung",
      voertuig: "Fahrzeug"
    },
    workflowEntiteit: {
      aflevering: "Auslieferung",
      bericht: "Nachricht",
      garantie: "Verträge",
      lead: "Lead",
      relatie: "Beziehung",
      verkooptraject: "Verkaufsprozess",
      voertuig: "Fahrzeug",
      werkplaatsafspraak: "Werkstatttermin",
      werkplaatsfactuur: "Werkstattrechnung"
    },
    missingSamenvoegvelden: "Seriendruckfelder",
    missingVoorwaarden: "Bedingungen",
    startstepTriggerRestart: "Arbeitsablauf erneut starten",
    startstepTriggerOnceOnly: "Arbeitsablauf nicht erneut starten",
    startstepTriggerDataEntry: "zum Zeitpunkt des Ausfüllens",
    startstepTriggerDataEntryCapital: "Zum Zeitpunkt des Ausfüllens",
    startDescriptionCount: "{filtericon} 1 Startbedingung |{filtericon} {count} Startbedingungen",		
    startDescriptionTriggerBefore: "vor",
    startDescriptionTriggerAfter: "nach",
    startDescriptionTrigger: "{startdescription} {beforeafter} dem ausgefüllten Datum",	
    startstepTriggerOnDate: "am ausgefüllten Datum", 
    startstepTriggerDate: "Das ausgefüllte Datum", 
    startstepCampagneTriggerDate: "Arbeitsablauf starten per", 
    startstepCampagneDate: "Datum", 
    startstepCampagneTime: "Uhrzeit", 
    startstepCampagneMax: "Höchstanzahl pro Tag", 
    webtrackingDisplay: "mindestens {amount} Wiedergaben seit Start",
    factuurDisplay: "mehr als {amount} {units}",
    moreThan: "mehr als",
    moreThanTitle: "Mehr als",
    lessThanTitle: "Weniger als",
    equalsTitle: "Gleich",
    timespanPassed: "in der Vergangenheit",
    timespanUnitsPassed: "vor {timespan}",
    timespanIsLess: "Ist kürzer als",    
    timespanIsOver: "Ist länger als",
    timespanIsLessSmall: "ist kürzer als",    
    timespanIsOverSmall: "ist länger als",
    dateIsWithIn: "fällt in",
    dateOptionSelect: "Wählen Sie einen Vergleich aus",
    dateIsWithInTimespan: "innerhalb von {timespan} {beforeorafter}",
    tenaamstellingDisplayMore: "vor mehr als {timespan}",
    tenaamstellingDisplayLess: "vor weniger als {timespan}",
    nextMaintenanceDisplayBefore: "innerhalb von {timespan} vor Nächster Inspektion",
    nextMaintenanceDisplayAfter: "innerhalb von {timespan} nach Nächster Inspektion",
    nextAPKDisplayBefore: "innerhalb von {timespan} vor TÜV",
    nextAPKDisplayAfter: "innerhalb von {timespan} nach TÜV",
    lastSoldVehicleDisplayBefore: "innerhalb von {timespan} vor Letzter Anmeldung",
    lastSoldVehicleDisplayAfter: "innerhalb von {timespan} nach Letzter Anmeldung",
    voertuigDisplayMore: "mehr als {amount}",
    voertuigDisplayLess: "weniger als {amount}",
    voertuigDisplayEqual: "gleich {amount}",
    enterQuantity: "Geben Sie eine Anzahl ein",
    addDoelgroep: "Hinzufügen",
    multipleDoelgroepInformation: "Wenn eine Beziehung in mehreren Zielgruppen enthalten ist, wird für das Ausfüllen von Seriendruckfeldern in Nachrichten die oberste Zielgruppe verwendet, in diesem Fall: {doelgroepNaam}",
    errorMessages: {
      andOr: "und/oder",
      readonly: "Dieser Arbeitsablauf kann nicht mehr geändert werden, da die Kampagne bereits begonnen hat.",
      workflowTitle: "Name des Arbeitsablaufs fehlt.",
      startStepNoTrigger: "Startzeitpunkt bei Start ist erforderlich.",
      startStepNoGarantie: "Ein Vertragstyp ist erforderlich.",
      startStepNoStartMoment: "Ein Startzeitpunkt ist erforderlich.",
      startStepNoStartTijd: "Eine Startzeit ist erforderlich.",
      startStepNoAantalPerDag: "Eine Höchstanzahl Arbeitsabläufe pro Tag ist erforderlich.",
      startStepNoDoelgroepen: "Es muss mindestens eine Zielgruppe ausgewählt werden.",
      startStepNoEntiteit:"Es muss eine Auswahl getroffen werden, um pro Beziehung oder Fahrzeug zu beginnen.",
      startStepInactiveDoelgroep: "Eine oder mehrere Zielgruppen sind inaktiv.",
      startStepNoVoertuigEntiteit: "In allen Zielgruppen muss ein Fahrzeug enthalten sein.",
      eindStep: "Ein oder mehrere Endschritte fehlen.",
      eindStepNoResult: "Ergebnisse in Endschritten sind erforderlich.",
      wachtStep: "Zeitraum in Warteschritten ist erforderlich.",
      berichtStep: "Bei einem Nachrichtenschritt muss eine Nachricht ausgewählt sein.",
      berichtStepMissingEntities: "Die Nachricht enthält {entiteiten}, die in diesem Arbeitsablauf nicht ausgefüllt werden.",
      voorwaardeStepMissingEntities:"Der Arbeitsablauf enthält {entiteiten}, die nicht evaluiert werden können.",
      lefStep: {
        omschrijving: "Beschreibungen in LEF-Schritten sind erforderlich.",
        kwalificaties: "Qualifizierungen in LEF-Schritten sind erforderlich.",
        leadtype:"Lead-Typ in LEF-Schritten ist bei Verkauf und Auslieferungen erforderlich."
      },
      voorwaardeCombinatieStep: {
        criteria:"Kriterien in Bedingungsschritten mit mehreren Unterbedingungen sind erforderlich."
      },
      voorwaardeStep: {
        criteria: "Kriterien in Bedingungsschritten sind erforderlich.",
        gemist: "Verpasste Beendigungsgründe in Bedingungsschritten sind erforderlich.",
        soortLead: "Lead-Art in Bedingungsschritten sind erforderlich.",
        gewensteAutosoorten:"Gewünschte Fahrzeugtypen in Bedingungsschritten sind erforderlich.",
        carmake: "Marken in Bedingungsschritten sind erforderlich.",
        vestigingen: "Standorte in Bedingungsschritten sind erforderlich.",
        afsluitRedenen:"Erfolgreiche Beendigungsgründe in Bedingungsschritten sind erforderlich.",
        link: "Links in Bedingungsschritten sind erforderlich.",
        leadvoertuigtype:"Der Fahrzeugtyp in Lead-Bedingungsschritten ist erforderlich.",
        gemistSales:"Verpasste Beendigungsgründe in Bedingungsschritten sind erforderlich.",
        salesAutosoorten:     "Verkaufs-Fahrzeugtypen in Bedingungsschritten sind erforderlich.",
        aftersalesVoertuigCategorieen:       "Fahrzeugkategorie in Bedingungsschritten ist erforderlich.",
        salesAutomake: "Marken in Bedingungsschritten sind erforderlich.",
        orderAutomake: "Marken in Bedingungsschritten sind erforderlich.",
        vestigingenExternSales:       "Standorte in Bedingungsschritten sind erforderlich.",
        orderAutosoorten:"Auftrags-Fahrzeugtypen in Bedingungsschritten sind erforderlich.",
        vestigingenExternOrder:"Standorte in Bedingungsschritten sind erforderlich.",
        actiegroepVerkocht:"Aktionsgruppe verkauft in Bedingungsschritten ist erforderlich.",
        actiegroepGoedgekeurd:"Aktionsgruppe genehmigt in Bedingungsschritten ist erforderlich.",
        actiegroepBesteld:"Aktionsgruppe bestellt in Bedingungsschritten ist erforderlich.",
        actiegroepAutoBinnen: "Aktionsgruppe Auto eingetroffen in Bedingungsschritten ist erforderlich.",
        actiegroepAfspraakLevering: "Aktionsgruppe Termin Lieferung in Bedingungsschritten ist erforderlich.",
        actiegroepAfgeleverd: "Aktionsgruppe ausgeliefert in Bedingungsschritten ist erforderlich.",
        klantCategorie: "Kundenkategorie in Bedingungsschritten ist erforderlich.",
        kwalificatie: "Qualifizierung in Bedingungsschritten ist erforderlich.",
        leadbron: "Lead-Quelle in Bedingungsschritten ist erforderlich.",
        lopendeWorkflows: "Laufende Arbeitsabläufe in Bedingungsschritten sind erforderlich.",
        timespan: "Zeitraum in Bedingungsschritten ist erforderlich.",
        referenceid: "Referenzdatum in Bedingungsschritten ist erforderlich.",
        garantie: "Verträgen in Bedingungsschritten sind erforderlich.",
        factuursoorten: "Rechnungstypen in Bedingungsschritten sind erforderlich.",
        heeftOpenLead: "Lead-Typ in Bedingungsschritten ist erforderlich.",
        heeftInitiatief: "Initiative in Bedingungsschritten ist erforderlich.",
        optinProfiel: "Profil bei Opt-in-Kontrolle in Bedingungsschritten ist erforderlich.",
        optinMedium: "Medium bei Opt-in-Kontrolle in Bedingungsschritten ist erforderlich.",
        expiratieMaxSubvoorwaarden: "Wartezeit kann bei mehreren Unterbedingungen nicht verwendet werden.",
        bedragnegatief: "Kein gültiger Betrag ausgefüllt.",
        aantalnegatief: "Keine gültige Anzahl ausgefüllt.",
        invalidCompare: "Kein gültiger Vergleicher ausgefüllt.",
        relatieNamen: "Ein oder mehrere Namen in Bedingungsschritten sind erforderlich.",
        orderVoertuigCategorie: "Ein oder mehrere Kategorien in Bedingungsschritten sind erforderlich.",
        orderVoertuigBrandstof: "Ein oder mehrere Kraftstoffe in Bedingungsschritten sind erforderlich.",
        leadVoertuigBrandstof: "Ein oder mehrere Kraftstoffe in Bedingungsschritten sind erforderlich.",
        aftersalesVoertuigBrandstof: "Ein oder mehrere Kraftstoffe in Bedingungsschritten sind erforderlich.",
        invalidwebtrackers: "Ein oder mehrere Webtracker in Bedingungsschritten sind erforderlich."
      },
      campagneAantalLimitExceeded: "Die Höchstzahl, die pro Tag versandt werden darf, ist auf {count} begrenzt. Wenn dies erhöht werden muss, wenden Sie sich bitte an unseren Kundendienst.",
      campagneStartToday: "Achtung: Diese Kampagne wird in {count} Minuten beginnen.",
      berichtStepMissingVestiging: "Da zu diesem Punkt im Arbeitsablauf keine Auslieferung, kein Verkaufsprozess, kein Lead oder kein Fahrzeug garantiert werden kann, wird zum Ausfüllen der Standort-Seriendruckfelder in dieser Nachricht der Standardstandort verwendet.",
    },
  },
    
  sjablonen: {
    title: "Nachrichten",
    searchPlaceholder: "Nachricht suchen ...",
    blocks: "Freihalten",
    general: "Allgemein",
    inworkflows: "Arbeitsabläufe",
    communication: "Kommunikation",
    category: "Kategorie",
    workflow: "Arbeitsablauf",
    make: "Marke",
    labels: "Etiketten",
    email: "E-Mail",
    sms: "SMS",
    copy: "Kopieren",
    createTemplate: "Vorlage erstellen",
    createdTemplate: "Vorlage erstellt"
  },

  sjabloon: {
    copied: "Kopie von {title}",
    panelTitle: "Nachrichtendetails",
    panelDetailTitle: "Allgemeine Information",
    title: "Nachricht",
    subject: "Betreff",
    category: "Kategorie",
    label: "Etikett",
    insertTitle: "Geben Sie einen Titel ein ...",
    insertSubject: "Geben Sie einen Betreff ein ...",
    chooseCategory: "Kategorie auswählen",
    allCategories: "Alle Kategorien",
    actief: "Aktiv",
    inactief: "Inaktiv",
    allLabels: "Alle Etiketten",
    selectLabel: "Etikett(en) auswählen",
    email: "E-Mail",
    sms: "SMS",
    amount: "Anzahl",
    normalCharacters: "normale Zeichen",
    specialCharacters: "spezielle Zeichen",
    maximumCharacters: "Maximum erreicht",
    opgeslagen: "Gespeichert",
    mergetags: "Seriendruckfelder",
    selectmergetag: "Seriendruckfeld einfügen",
    undoSMS: "SMS-Nachricht ist gelöscht",
    undoEmail: "E-Mail-Nachricht ist gelöscht",
    chooseBericht: "Nachricht auswählen",
    modelSpecificLayout: "Modellspezifische Formatierung",
    merk: "Marke",
    model: "Modell",
    allMakes: "Alle Marken",
    selectMerk: "Marke hinzufügen",
    selectModel: "Modell hinzufügen",
    nonSelectedMerk: "Alle Marken",
    nonSelectedModel: "Alle Modelle",
    selectedMerk: "Sonstige Marken",
    selectedModel: "Sonstige Modelle",
    notAvailableMerk: "Marke nicht verfügbar",
    notAvailableOpties: "Keine Optionen verfügbar",
    noResult: "Kein Ergebnis gefunden",
    merkDeleted: "Marke ist gelöscht",
    modelDeleted: "Modell ist gelöscht",
    preview: "Beispiel",
    sendtest: "Testnachricht",
    saved: "Nachricht ist gespeichert",
    saveRow: "Zeile spiechern",
    savedRow: "Gespeichert Zeile",
    warnExistingRowUpdate: "Diese Zeile wurde bereits gespeichert unter",
    voorkeurinformatie:"Durch Ziehen der Registerkarten können die Vorlieben während des Sendens festgelegt werden.",
    smsinformatie: "Die Anzahl SMS wird angezeigt. Eine SMS mit normalen Satzzeichen enthält die üblichen 160 Zeichen. Mit besonderen Satzzeichen beträgt die maximale Anzahl Zeichen 70. Die Länge der SMS hängt vom Inhalt der Seriendruckfelder ab.",
    noWorkflow: "Nicht gekoppelt an einen Arbeitsablauf",
    testSMSDetailTitle: "Test-SMS",
    testSMSTitle: "Test senden an",
    insertPhoneNumber: "Geben Sie eine Mobilfunknummer ein ...",
    testMailDetailTitle: "Test-E-Mail",
    testEmailTitle: "Test senden an",
    insertEmail: "Geben Sie eine E-Mail-Adresse ein ...",
    testMailSend: "Test-E-Mail ist versandt",
    testMailSendFailed: "Test-E-Mail ist nicht versandt",
    testSMSSend: "Test-SMS ist versandt",
    testSMSSendFailed: "Test-SMS ist nicht versandt",
    webtrackingactive: "Webtracking",
    consumersettingsactive: "Opt-in",
    consumersettingslabel: "Profil",
    consumersettings: "Wählen Sie ein Profil...",
    errormessages: {
      title: "Das Feld Nachricht ist erforderlich",
      omschrijving: "Das Feld Betreff ist erforderlich",
      omschrijvingTooLong: "Das Feld Betreff darf nicht mehr als 255 Zeichen enthalten." ,
      testemailaddress: "Eine gültige E-Mail-Adresse ist erforderlich",
      testephonenumber: "Eine gültige Mobilfunknummer ist erforderlich",
      isActiveInvalid: "Nachricht wird noch in einem aktiven Arbeitsablauf verwendet",
      consumersettingsInvalid: "Wählen Sie ein Profil bei Opt-in",
      consumersettingsTag: "In einer oder mehreren E-Mails fehlt ein Opt-in-Link zum Abbestellen."
    },
  },

  sentMessages: {
    filterAllMedia: "Alle Kommunikationsarten",
    filterAllUnsent: "Alle Meldungen",
    filterAllWorkflows: "Alle Arbeitsabläufe",
    filterAllMessages: "Alle Nachrichten",
    selectWorkflow: "Wählen Sie einen Arbeitsabläufe",
    selectMessage: "Wählen Sie einen Nachrichten",    
    versturen: "Absenden",
    menuTitle: "Nicht versandte Nachrichten",
    sent: "Versandte Nachrichten",
    title: "Nicht versandte Nachricht ({count}) | Nicht versandte Nachrichten ({count})",
    workflow: "Arbeitsablauf",
    workflows: "Arbeitsabläufe",
    email: "E-Mail",
    sms: "SMS",
    recipient: "Empfänger",
    status: "Fehlerstatus",
    errorMessage: "Fehlermeldung",
    delete: "Löschen",
    confirmDelete: "Bestätigen",
    communication: "Kommunikation",
    berichtTitel: "Nachricht Titel",
    badgeTitle: "1 nicht versandte Nachricht | {count} nicht versandte Nachrichten",
    notificationType: "Art der Benachrichtigung",
    messages: "Nachrichten"
  },
};
