















export default {
  name: "message-icon",
  props: {
    title: {
      type: String,
      default: "Message icon"
    },
    decorative: {
      type: Boolean,
      default: false
    }
  }
}
